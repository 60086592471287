import { Slide } from '@material-ui/core';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api_patch_serialize, api_post_serialize, api_post } from 'utils/Api';
import { enqueueSnackbar } from '../notifier/notifierSlice';
import { serialize } from 'object-to-formdata';
import axios from './../../../utils/axios';
import { Value } from 'sass';

// urls:
const url = 'user-materiels';

// fetch all userMaterials from server
export const getAllUserMaterials = createAsyncThunk(
  'user-material/getAll',
  async ({ page, options, rowsPerPage }, thunkAPI) => {
    try {
      const fetchedUser_Materials = await axios.get(
        url + `?page=${page}${options}&limit=${rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (fetchedUser_Materials.status === 200) {
        const usermaterial = fetchedUser_Materials.data.data.map(
          (userMaterial) => {
            return {
              _id: userMaterial?._id,
              delivred_at: userMaterial?.delivred_at,
              received_at: userMaterial?.received_at,
              user_Id: userMaterial?.userObj?._id,
              username: userMaterial?.userObj?.username,
              material_Id: userMaterial?.matObj?._id,
              materialName: userMaterial?.matObj?.name,
              modelName: userMaterial?.modObj?.name,
              departmentName: userMaterial?.depObj?.name,
              locationName: userMaterial?.locObj?.name,
              materialNote: userMaterial?.matObj?.note,
              serial_number: userMaterial?.matObj?.serial_number,
              materialCreated_at: userMaterial?.matObj?.createdAt,
              userCreated_at: userMaterial?.userObj?.createdAt,
              materialUpdated_at: userMaterial?.matObj?.updatedAt,
              userUpdated_at: userMaterial?.userObj?.updatedAt,
            };
          }
        );
        return {
          data: usermaterial,
          meta: fetchedUser_Materials.data.meta,
        };
      }
      throw new Error(fetchedUser_Materials.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);


// show one user-material
export const fetchUserMaterial = createAsyncThunk(
  ' user-material/getOne',
  async (userMaterialId, thunkAPI) => {
    try {
      const userMaterialResponse = await axios.get(`${url}/${userMaterialId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (userMaterialResponse.status === 200) {
        return userMaterialResponse.data;
      }
      throw new Error(userMaterialResponse.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getUserHistoryMaterial = createAsyncThunk(
  ' user-material/getUserHistoryMaterial',
  async (body, thunkAPI) => {
    try {
      const userHistoryMaterialResponse = await axios.get(
        `${url}/myUserMaterials?page=${body.page}&limit=${body.rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (userHistoryMaterialResponse.status === 200) {
        return userHistoryMaterialResponse.data;
      }
      throw new Error(userHistoryMaterialResponse.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getUserMaterialHistory = createAsyncThunk(
  ' user-material-history/getOne',
  async (body, thunkAPI) => {
    try {
      console.log({ body })
      const userMaterialDataHistory = await axios.get(
        `${url}/?history=true&user=${body.userId}&page=${body.page}&limit=${body.rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (userMaterialDataHistory.status === 200) {
        return userMaterialDataHistory.data;
      }
      throw new Error(userMaterialDataHistory.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDepartmentMaterialHistory = createAsyncThunk(
  ' department-material-history/getOne',
  async (body, thunkAPI) => {
    try {
      const userMaterialDataHistory = await axios.get(
        `${url}/?history=true&departmentHistory=${body.departmentId}&page=${body.page}&limit=${body.rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (userMaterialDataHistory.status === 200) {
        return userMaterialDataHistory.data;
      }
      throw new Error(userMaterialDataHistory.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getLocationMaterialHistory = createAsyncThunk(
  ' location-material-history/getOne',
  async (body, thunkAPI) => {
    try {
      const userMaterialDataHistory = await axios.get(
        `${url}/?history=true&locationHistory=${body.locationId}&page=${body.page}&limit=${body.rowsPerPage}&user=null`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (userMaterialDataHistory.status === 200) {
        return userMaterialDataHistory.data;
      }
      throw new Error(userMaterialDataHistory.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// create a new userMaterial
export const createUserMaterial = createAsyncThunk(
  'userMaterial/create',
  async (userMaterialToCreate, thunkAPI) => {
    try {
      userMaterialToCreate.accessToken = thunkAPI.getState().auth.token;
      if (userMaterialToCreate?.user_id === "" || userMaterialToCreate?.user_id === null) {
        delete userMaterialToCreate?.user_id;
      }
      if (userMaterialToCreate?.department_id === "" || userMaterialToCreate?.department_id === null) {
        delete userMaterialToCreate?.department_id;
      }
      if (userMaterialToCreate?.location_id === "" || userMaterialToCreate?.location_id === null) {
        delete userMaterialToCreate?.location_id;
      }
      const createdUserMaterial = await axios.post(url, userMaterialToCreate, {
        headers: {
          Authorization: `Bearer ${userMaterialToCreate.accessToken}`,
        },
      });
      if (createdUserMaterial.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              createdUserMaterial?.data?.message || 'User material is succesfully created',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.pathname = '/userMaterial';
        }, 500);
        return createdUserMaterial;
      }
      throw new Error(createdUserMaterial.status);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

// old:
// const formData = serialize(userMaterialUpdateData);
// console.log(formData)

// edit => update user-material
export const editUserMaterial = createAsyncThunk(
  'userMaterial/edit',
  async (userMaterialUpdateData, thunkAPI) => {
    let data;
    try {
      const id = userMaterialUpdateData?.id;
      // delete userMaterialUpdateData.id
      const updateUrl = `${url}`
      if (userMaterialUpdateData?.user_id === "" || userMaterialUpdateData?.user_id === null) {
        delete userMaterialUpdateData?.user_id;
      }
      if (userMaterialUpdateData?.department_id === "" || userMaterialUpdateData?.department_id === null) {
        delete userMaterialUpdateData?.department_id;
      }
      if (userMaterialUpdateData?.location_id === "" || userMaterialUpdateData?.location_id === null) {
        delete userMaterialUpdateData?.location_id;
      }
      if (userMaterialUpdateData?.received_at === "" || userMaterialUpdateData?.received_at === null) {
        delete userMaterialUpdateData?.received_at;
      }

      const updatedUserMaterial = api_patch_serialize(updateUrl, id, userMaterialUpdateData, true);
      data = await updatedUserMaterial;
      if (data.status === "success") {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              updatedUserMaterial?.message ||
              'User-Material is successfully updated',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.pathname = '/userMaterial';
        }, 500);
        return updatedUserMaterial.data
      }
      if (data.status === "fail") {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              data?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
      }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

// delete a user-material
export const removeUserMaterial = createAsyncThunk(
  'user-material/delete',
  async (id, thunkAPI) => {
    try {
      const deletedUserMaterial = await axios.delete(`${url}/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (deletedUserMaterial.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              deletedUserMaterial?.data?.message ||
              'User-Material is successfully deleted',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        return deletedUserMaterial.data.data;
      }
      throw new Error(deletedUserMaterial.status);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong, Could not delete it!',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);
export const importFileUserMaterial = createAsyncThunk(
  "userMaterial/importFileUserMaterial",
  async (query, thunkAPI) => {
    const { requestValues } = query;
    let data;
    try {
      const importedData = await api_post(
        `${url}/import`,
        requestValues,
        true
      );
      if (importedData.status === "success" && importedData.data.dataSuccessfullyCreated.length !== 0 && importedData.data.wrongData.length === 0) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              importedData?.message ||
              "User Materials data imported successfully",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.pathname = "/userMaterial";
        }, 500);
      }
      if (importedData.status === "fail") {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              importedData?.message ||
              "Import data has failed",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
      }
      return importedData.data;

      //   throw new Error(importedData?.wrongData?.map((line, index) => line));
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log("error", err?.message);
      return Promise.reject(err.message ? err.message : err?.message);
    }
  }
);

export const importFileDepartmentMaterial = createAsyncThunk(
  "userMaterial/importFileDepartmentMaterial",
  async (query, thunkAPI) => {
    const { requestValues } = query;
    let data;
    try {
      const importedData = await api_post(
        `${url}/departmentImport`,
        requestValues,
        true
      );
      if (importedData.status === "success" && importedData.data.dataSuccessfullyCreated.length !== 0 && importedData.data.wrongData.length === 0) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              importedData?.message ||
              "Department Materials data imported successfully",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.pathname = "/userMaterial";
        }, 500);

      }
      if (importedData.status === "fail") {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              importedData?.message ||
              "Import data has failed",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
      }
      return importedData.data;

      //   throw new Error(importedData?.wrongData?.map((line, index) => line));
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log("error", err?.message);
      return Promise.reject(err.message ? err.message : err?.message);
    }
  }
);
export const getUserMaterielsById = createAsyncThunk(
  "materiels/getUserMaterielsById",
  async (body, thunkAPI) => {
    try {
      const fetchedMaterials = await axios.get("materiels" + `/${body.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (fetchedMaterials.status === 200) {
        const { data } = fetchedMaterials;
        return data;
      }
      throw new Error(fetchedMaterials.statusText);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const initialState = {
  userMaterialData: [],
  userHistoryMaterial: [],
  userMaterialById: [],
  userMaterialId: null,
  statusUserMaterial: "",
  userMaterial: {
    data: [],
    status: "idle",
    error: null,
  },
  userMaterialHistory: null,
  departmentMaterialHistory: null,
  locationMaterialHistory: null,
  wrongData: {
    data: [],
    status: "idle",
    error: null,
  },
  wrongDataDep: {
    data: [],
    status: "idle",
    error: null,
  },
  meta: null,
};

const userMaterialSlice = createSlice({
  name: "userMaterialSlice",
  initialState: initialState,
  reducers: {
    getMaterialId: (state, action) => {
      state.userMaterialId = action.payload;
      localStorage.setItem("materialId", state.userMaterialId?.name);
    },
    emptyArray: (state, action) => {
      state.userMaterialById = [];
    },
  },
  extraReducers: (builder) => {
    //  reducers
    builder
      // get user-materials request
      .addCase(getAllUserMaterials.pending, (state, action) => { })
      .addCase(getAllUserMaterials.fulfilled, (state, action) => {
        state.userMaterialData = action.payload;
      })
      .addCase(getAllUserMaterials.rejected, (state, action) => { })
      // get a user-History-material async request
      .addCase(getUserHistoryMaterial.pending, (state, action) => { })
      .addCase(getUserHistoryMaterial.fulfilled, (state, action) => {
        state.userHistoryMaterial = action.payload;
      })
      .addCase(getUserHistoryMaterial.rejected, (state, action) => { })
      // get a user-material async request
      .addCase(fetchUserMaterial.pending, (state, action) => {
        state.userMaterial.status = "loading";
      })
      .addCase(fetchUserMaterial.fulfilled, (state, action) => {
        state.userMaterial.status = "succeeded";
        state.userMaterial.data = action.payload.data;
      })
      .addCase(fetchUserMaterial.rejected, (state, action) => {
        state.userMaterial.status = "failed";
      })
      // get  user-material-history async request
      .addCase(getUserMaterialHistory.pending, (state, action) => { })
      .addCase(getUserMaterialHistory.fulfilled, (state, action) => {
        const historyData = action.payload?.data?.map((userMaterial) => {
          return {
            _id: userMaterial?._id,
            delivred_at: userMaterial?.delivred_at,
            received_at: userMaterial?.received_at,
            user_Id: userMaterial?.userObj?._id,
            username: userMaterial?.userObj?.username,
            material_Id: userMaterial?.matObj?._id,
            materialName: userMaterial?.matObj?.name,
            serial_number: userMaterial?.matObj?.serial_number,
            materialNote: userMaterial?.matObj?.note,
            materialCreated_at: userMaterial?.matObj?.createdAt,
            userCreated_at: userMaterial?.userObj?.createdAt,
            materialUpdated_at: userMaterial?.matObj?.updatedAt,
            userUpdated_at: userMaterial?.userObj?.updatedAt,
          };
        });
        const meta = action.payload?.meta
        state.userMaterialHistory = { historyData, meta };
      })
      .addCase(getUserMaterialHistory.rejected, (state, action) => { })
      // get  department-material-history async request
      .addCase(getDepartmentMaterialHistory.pending, (state, action) => { })
      .addCase(getDepartmentMaterialHistory.fulfilled, (state, action) => {
        const historyData = action.payload?.data?.map((userMaterial) => {
          return {
            _id: userMaterial?._id,
            delivred_at: userMaterial?.delivred_at,
            received_at: userMaterial?.received_at,
            user_Id: userMaterial?.userObj?._id,
            username: userMaterial?.userObj?.username,
            material_Id: userMaterial?.matObj?._id,
            materialName: userMaterial?.matObj?.name,
            serial_number: userMaterial?.matObj?.serial_number,
            materialNote: userMaterial?.matObj?.note,
            materialCreated_at: userMaterial?.matObj?.createdAt,
            userCreated_at: userMaterial?.userObj?.createdAt,
            materialUpdated_at: userMaterial?.matObj?.updatedAt,
            userUpdated_at: userMaterial?.userObj?.updatedAt,
          };
        });
        const meta = action.payload?.meta
        state.userMaterialHistory = { historyData, meta };
      })
      .addCase(getDepartmentMaterialHistory.rejected, (state, action) => { })
      // get  location-material-history async request
      .addCase(getLocationMaterialHistory.pending, (state, action) => { })
      .addCase(getLocationMaterialHistory.fulfilled, (state, action) => {
        const historyData = action.payload?.data?.map((userMaterial) => {
          return {
            _id: userMaterial?._id,
            delivred_at: userMaterial?.delivred_at,
            received_at: userMaterial?.received_at,
            user_Id: userMaterial?.userObj?._id,
            username: userMaterial?.userObj?.username,
            material_Id: userMaterial?.matObj?._id,
            materialName: userMaterial?.matObj?.name,
            serial_number: userMaterial?.matObj?.serial_number,
            materialNote: userMaterial?.matObj?.note,
            materialCreated_at: userMaterial?.matObj?.createdAt,
            userCreated_at: userMaterial?.userObj?.createdAt,
            materialUpdated_at: userMaterial?.matObj?.updatedAt,
            userUpdated_at: userMaterial?.userObj?.updatedAt,
          };
        });
        const meta = action.payload?.meta
        state.userMaterialHistory = { historyData, meta };
      })
      .addCase(getLocationMaterialHistory.rejected, (state, action) => { })
      // create userMaterial request
      .addCase(createUserMaterial.pending, (state, action) => { })
      .addCase(createUserMaterial.fulfilled, (state, action) => { })
      .addCase(createUserMaterial.rejected, (state, action) => { })
      // edit userMaterial request
      .addCase(editUserMaterial.pending, (state, action) => { })
      .addCase(editUserMaterial.fulfilled, (state, action) => { })
      .addCase(editUserMaterial.rejected, (state, action) => { })
      // remove user-material request
      .addCase(removeUserMaterial.pending, (state, action) => { })
      .addCase(removeUserMaterial.fulfilled, (state, action) => { })
      .addCase(removeUserMaterial.rejected, (state, action) => { })
      //import user - material
      .addCase(importFileUserMaterial.pending, (state, action) => {
        state.wrongData.status = "loading";
      })
      .addCase(importFileUserMaterial.fulfilled, (state, action) => {
        if (action.payload?.wrongData?.length !== 0) {
          state.wrongData.status = "succeeded";
          state.wrongData.data = action.payload?.wrongData;
        }
        if (action.payload?.wrongData?.length === 0) {
          state.wrongData.data = [];
        }
      })
      .addCase(importFileUserMaterial.rejected, (state, action) => {
        state.wrongData.status = "failed";
        state.wrongData.error = action.payload;
      })
      .addCase(getUserMaterielsById.pending, (state, action) => {
        state.statusUserMaterial = "pending";
      })
      .addCase(getUserMaterielsById.fulfilled, (state, action) => {
        state.userMaterialById = action.payload?.data;
        state.statusUserMaterial = "succeeded";
      })
      .addCase(getUserMaterielsById.rejected, (state, action) => {
        state.statusUserMaterial = "error";
      })
      //import Dep - material
      .addCase(importFileDepartmentMaterial.pending, (state, action) => {
        state.wrongDataDep.status = "loading";
      })
      .addCase(importFileDepartmentMaterial.fulfilled, (state, action) => {
        if (action.payload?.wrongData?.length !== 0) {
          state.wrongDataDep.status = "succeeded";
          state.wrongDataDep.data = action.payload?.wrongData;
        }
        if (action.payload?.wrongData?.length === 0) {
          state.wrongDataDep.data = [];
        }
      })
      .addCase(importFileDepartmentMaterial.rejected, (state, action) => {
        state.wrongDataDep.status = "failed";
        state.wrongDataDep.error = action.payload;
      });
  },
});
export const { getMaterialId, emptyArray } = userMaterialSlice.actions;
export default userMaterialSlice.reducer;


