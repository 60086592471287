import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { enqueueSnackbar } from '../notifier/notifierSlice';
import Slide from '@material-ui/core/Slide';

const initialState = {
    permissions: [],
    status: 'idle',
    error: null,
    permission: {
        data: [],
        status: 'idle',
        error: null,
    },
    meta: null,
};

export const fetchPermissions = createAsyncThunk('permission/fetchPermissions',
    async ({ page, options, rowsPerPage }) => {
    let data;
    try {
        const response = await axios.get(`/permissions?page=${page}${options}&limit=${rowsPerPage}`);

        data = await response.data;
        if ((response.status = 200)) {
            return data;
        }
        throw new Error(response.statusText);
    } catch (err) {
        console.log(err);
        return Promise.reject(err.message ? err.message : data?.message);
    }
});

export const fetchOnePermission = createAsyncThunk(
    "permission/fetchOnePermission",
    async (id) => {
      let data;
      try {
        const response = await axios.get(`permissions/${id}`);
        data = await response.data;
        if ((response.status = 200)) {
          return data.payload;
        }
        throw new Error(response.statusText);
      } catch (err) {
        console.log(err);
        return Promise.reject(err.message ? err.message : data?.message);
      }
});

export const addNewPermission = createAsyncThunk(
    "permission/addNewPermission",
    async (query, thunkAPI) => {
      const { requestValues } = query;
      let data;
      try {
        const response = await axios.post(`permissions`, requestValues);
        data = await response.data;
        if (response.status === 200) {
          thunkAPI.dispatch(
            enqueueSnackbar({
              message: data.message,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                TransitionComponent: Slide,
              },
            })
          );
          setTimeout(() => {
            window.location.href = "/settings/permissions";
          }, 2000);
          return data.payload;
        }
        throw new Error(response.statusText);
      } catch (err) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: err,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        console.log(err);
        return Promise.reject(err.message ? err.message : data?.message);
      }
});
  
export const editPermission = createAsyncThunk(
    "permission/editPermission",
    async (query, thunkAPI) => {
      const { id, requestValues } = query;
      let data;
      try {
        const response = await axios.put(`admin-groups/${id}`, requestValues);
        data = await response.data;
        if (response.status === 200) {
          thunkAPI.dispatch(
            enqueueSnackbar({
              message: data.message,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                TransitionComponent: Slide,
              },
            })
          );
          setTimeout(() => {
            window.location.href = "/settings/permissions";
          }, 2000);
          return data.payload;
        }
        throw new Error(response.statusText);
      } catch (err) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: err,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        console.log(err);
        return Promise.reject(err.message ? err.message : data?.message);
      }
});

const permissionSlice = createSlice({
    name: 'permissionSlice',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchPermissions.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchPermissions.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.permissions = action.payload.data;
            state.meta = action.payload?.meta?.totalDocs;
        },
        [fetchPermissions.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [fetchOnePermission.pending]: (state, action) => {
            state.permission.status = 'loading';
        },
        [fetchOnePermission.fulfilled]: (state, action) => {
            state.permission.status = 'succeeded';
            state.permission.data = action.payload;
        },
        [fetchOnePermission.rejected]: (state, action) => {
            state.permission.status = 'failed';
            state.permission.error = action.payload;
        },
        [addNewPermission.pending]: (state, action) => {
            state.permission.status = 'loading';
        },
        [addNewPermission.fulfilled]: (state, action) => {
            state.permission.status = 'succeeded';
            state.permission.data = action.payload;
        },
        [addNewPermission.rejected]: (state, action) => {
            state.permission.status = 'failed';
            state.permission.error = action.payload;
        },
        [editPermission.pending]: (state, action) => {
            state.permission.status = 'loading';
        },
        [editPermission.fulfilled]: (state, action) => {
            state.permission.status = 'succeeded';
            state.permission.data = action.payload;
        },
        [editPermission.rejected]: (state, action) => {
            state.permission.status = 'failed';
            state.permission.error = action.payload;
        },
    },
});

export default permissionSlice.reducer;
