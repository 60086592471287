/* -- Headers & API_URL ------------------ */
import headers from "../helpers/headers";
import headersFormData from "../helpers/headersFormData";
import mapValuesToFormData from "../helpers/mapValuesToFormData";
import { setItemFromStorage } from "./Storage";
import { serialize } from "object-to-formdata";
import { getItemFromStorage } from "../utils/Storage.js";

const apiUrl = process.env.REACT_APP_API_URL;

const token = getItemFromStorage("token");
/* -- Default methods -------------------- */
const api_get = async (base_url) => {
  return fetch(`${apiUrl}${base_url}`, {
    method: "GET",
    headers,
  }).then((response) => {
    return response.json();
  });
};

const api_get_rows = async (base_url) => {
  return fetch(`${base_url}`, {
    method: "GET",
    headers,
  }).then((response) => {
    return response.json();
  });
};

const api_post_serialize = async (base_url, data, serialize_it) => {
  let seriali = serialize_it || false;
  return fetch(`${apiUrl}${base_url}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    headers: seriali === true ? headersFormData : headers,
    body:
      seriali === true
        ? serialize(data, { indices: true })
        : JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

const api_post = async (base_url, data, serialize_it) => {
  let seriali = serialize_it || false;
  return fetch(`${apiUrl}${base_url}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    headers: seriali === true ? headersFormData : headers,
    body:
      seriali === true
        ? serialize(data, { indices: true })
        : JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

const api_patch_serialize = async (base_url, id, data, serialize_it) => {
  let seriali = serialize_it || false;
  return fetch(`${apiUrl}${base_url}/${id}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    headers: seriali === true ? headersFormData : headers,
    body: seriali === true ? serialize(data) : JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

const api_put = async (base_url, data) => {
  return fetch(`${apiUrl}/${base_url}`, {
    method: "PUT",
    headers,
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json();
  });
};

const api_delete = async (base_url) => {
  return fetch(`${apiUrl}/${base_url}`, {
    method: "DELETE",
    headers,
  }).then((response) => {
    return response.json();
  });
};

const api_delete_many = async (deleteUrl, data, serialize_it) => {
  let seriali = serialize_it || false;
  const keyData = { 'items': data };
  return fetch(`${apiUrl}${deleteUrl}`, {
    method: "DELETE",
    headers: seriali === true ? headersFormData : headers,
    body: JSON.stringify(keyData),
  }).then((response) => {
    return response.json();
  });
};
const api_export_many = async (exportUrl, data) => {
  try {
    // Fetch file
    const response = await fetch(`${apiUrl}${exportUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: data ? JSON.stringify({ 'items': data }) : undefined,
    });

    // Convert response to blob
    const blob = await response.blob();

    // Create object URL and download link
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `FileName.csv`);

    // Append link to document and click it to trigger download
    document.body.appendChild(link);
    link.click();

    // Clean up
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
    window.location.reload()
  } catch (error) {
    console.error("Error downloading file:", error);
    // Handle error (e.g. show error message to user)
  }
};

const login = async (credentials) => {
  return fetch(`${apiUrl}/admin/auth/login_check`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then(async (response) => {
    const json = await response.json();
    if (response.ok) {

      await setItemFromStorage("token", json.payload.token);
    } else {
      throw new Error(json.message);
    }
  });
};


/* -- Export methods --------------------- */
export {
  api_get,
  api_post,
  api_put,
  api_delete,
  api_delete_many,
  api_export_many,
  login,
  api_post_serialize,
  api_get_rows,
  api_patch_serialize,
};
