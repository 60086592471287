import React, { useEffect, useState } from 'react';
import axios from './../../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  CardActions,
  TablePagination,
} from '@material-ui/core';
import SimpleToolbar from '../../components/Toolbar/SimpleToolbar';
import { getUserMaterialHistory } from 'redux/slices/userMaterial/userMaterialSlice';
import Loading from 'components/Loading/Loading';
import TablePaginationActions from "components/Table/TablePaginationActions";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  card: {
    marginTop: '2rem',
  },
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const UserShow = (props) => {
  const dispatch = useDispatch()
  const { Link, pageLink, title, ...rest } = props;
  const classes = useStyles();
  const [singleUser, setSingleUser] = useState(null);
  const url = '/users';

  const fetchOneUser = async () => {
    const fetchedUser = await axios.get(
      `${url}/${window.location.href.slice(-24)}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    return fetchedUser?.data?.data;
  };

  useEffect(() => {
    fetchOneUser().then((data) => setSingleUser(data));
  }, []);
  const handleMyHistoryPageChange = (event, page) => {
    setMyHistoryPage(page + 1);
  };
  const handleMyHistoryRowsPerPageChange = (event) => {
    setMyHistoryRowsPerPage(event.target.value);
  };
  const [myHistoryPage, setMyHistoryPage] = useState(1);
  const [myHistoryRowsPerPage, setMyHistoryRowsPerPage] = useState(10);
  let user_id = singleUser?._id

  useEffect(() => {
    function constructBody(key, value) {
      return {
        [key]: value,
        page: myHistoryPage,
        rowsPerPage: myHistoryRowsPerPage
      }
    }
    if (user_id) {
      dispatch(getUserMaterialHistory(constructBody('userId', user_id)));
    }
  }, [user_id, myHistoryPage, myHistoryRowsPerPage]);
  const { userMaterialHistory } = useSelector((state) => state.userMaterial);

  if (!userMaterialHistory) {
    return <Loading />
  }
  else {
    return (
      <div className={classes.root}>
        <SimpleToolbar Link={Link} pageLink={'/users'} title={'Inventory user'} />
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <Card className={classes.card}>
            <CardHeader title='User info' />
            <Divider />
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>ID</TableCell>
                  <TableCell>
                    <Typography variant='body2' color='textSecondary'>
                      {singleUser?._id}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>UserName</TableCell>
                  {
                    <TableCell>
                      <Typography variant='body2' color='textSecondary'>
                        {singleUser?.username || '(UNSET)'}
                      </Typography>
                      {/* <Label color={user.verified == '1' ? 'success' : 'error'}>
                  {user.verified == '1' ? 'User verified' : 'User unverified'}
                </Label>
                <Label color={user.completed == '1' ? 'success' : 'error'}>
                  {user.completed == '1'
                    ? 'User completed'
                    : 'User uncompleted'}
                </Label> */}
                    </TableCell>
                  }
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>
                    Name
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2' color='textSecondary'>
                      {singleUser?.email?.split('@')[0] || '(UNSET)'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>Email</TableCell>
                  <TableCell>
                    <Typography variant='body2' color='textSecondary'>
                      {singleUser?.email || '(UNSET)'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>Phone Number</TableCell>
                  <TableCell>
                    <Typography variant='body2' color='textSecondary'>
                      {'(+216) '}{+singleUser?.phoneNumber || '(UNSET)'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>Location</TableCell>
                  <TableCell>
                    <Typography variant='body2' color='textSecondary'>
                      {singleUser?.location_id?.name || '(UNSET)'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>Role</TableCell>
                  <TableCell>
                    <Typography variant='body2' color='textSecondary'>
                      {singleUser?.role?.label || '(UNSET)'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>
                    Department Identifier
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2' color='textSecondary'>
                      {singleUser?.department_id?.name || '(UNSET)'}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <Card className={classes.card}>
            <CardHeader title='Materials info' />
            <Divider />
            {userMaterialHistory?.historyData?.length > 0 ?
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Serial Number</TableCell>
                      <TableCell>Delivred at</TableCell>
                      <TableCell>Received at</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userMaterialHistory?.historyData?.map((history, index) => (
                      <TableRow >
                        <TableCell>
                          {history?.materialName}
                        </TableCell>
                        <TableCell>
                          {history?.serial_number}
                        </TableCell>
                        {history?.materialName &&
                          <TableCell>
                            {dayjs(history?.delivred_at).format('YYYY-MM-DD')}
                          </TableCell>}
                        {history?.materialName &&
                          <TableCell>
                            {history?.received_at !== null ? dayjs(history?.received_at).format('YYYY-MM-DD') : 'Not yet received'}
                          </TableCell>
                        }
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <CardActions className={classes.actions}>
                  <TablePagination
                    component="div"
                    count={
                      userMaterialHistory?.meta?.totalDocs !== undefined &&
                      userMaterialHistory?.meta?.totalDocs
                    }
                    onPageChange={handleMyHistoryPageChange}
                    onChangeRowsPerPage={handleMyHistoryRowsPerPageChange}
                    page={myHistoryPage - 1}
                    rowsPerPage={myHistoryRowsPerPage}
                    rowsPerPageOptions={[10, 24, 100]}
                    ActionsComponent={TablePaginationActions}
                  />
                </CardActions>
              </>

              :
              <h3 style={{ padding: '20px', color: '#808080', fontFamily: 'Roboto ,Oxygen, Ubuntu, Cantarell' }}>This user has not gotten materials yet</h3>
            }
          </Card>
        </Grid>
      </div>
    );
  }
};

export default UserShow;
