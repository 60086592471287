// @flow
import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  LinearProgress,
} from '@material-ui/core';
import { api_get } from 'utils/Api';
import departmentSlice from 'redux/slices/department/departmentSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
    transition: 'all 0.3s ease-in-out',
    borderRadius: '12px',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)',
    },
  },
  details: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#333',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      padding: '20px',
    },
  },
  avatar: {
    height: '110px',
    width: '110px',
    flexShrink: 0,
    flexGrow: 0,
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      height: '90px',
      width: '90px',
      marginBottom: '10px',
    },
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '5px',
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '10px 0',
    color: '#383838',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      margin: '5px 0',
    },
  },
  value: {
    fontWeight: 400,
    color: '#888',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
}));



const AccountProfile = (props) => {
  const { className, username, email, role, phoneNumber, departmentName, ...rest } = props;

  const classes = useStyles();
  console.log(departmentName);
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.details}>
          <Avatar alt='Person' className={classes.avatar} src={user.avatar} />
          <div className={classes.field}>
            Email :  <span className={classes.value}> {' '}{email}</span>
          </div>
          <div className={classes.field}>
            Role :   <span className={classes.value}>{role}</span>
          </div>
          <div className={classes.field}>
            Phone Number :   <span className={classes.value}>{phoneNumber}</span>
          </div>
          <div className={classes.field}>
            Department :   <span className={classes.value}>{departmentName?.name}</span>
          </div>
        </div>
      </CardContent>
      <Divider />
    </Card>



  );
};

export default AccountProfile;
