import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import HistoryIcon from "@material-ui/icons/History";

import RequestIcon from '@material-ui/icons/Mail';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArchiveIcon from '@material-ui/icons/Archive';
import AppsIcon from '@material-ui/icons/Apps';
import BarChartIcon from '@material-ui/icons/BarChart';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import HighlightIcon from '@material-ui/icons/Highlight';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import SettingsIcon from '@material-ui/icons/Settings';
import { Sidebar, Topbar, Footer } from './components';
import ErrorBoundary from '../../components/ErrorHandler/ErrorBoundary';
import { Button } from 'bootstrap';
import { resizeSidebarText } from 'redux/slices/extra/showSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'redux/slices/auth/userSlice';
import { getUsers } from 'utils/methods';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 150,
  },
  content: {
    height: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100%)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));


const Main = (props) => {

  const { children } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme?.breakpoints?.down('xs'), {
    defaultMatches: null,
  });
  const [openSidebar, setOpenSidebar] = useState(!isDesktop);
  const [open, setOpen] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const handleSidebarOpen = () => {
    setOpenSidebar(!openSidebar);
    setOpen(false);
  };

  const handleSidebar = () => {
    setOpenSidebar(true);
    if (openSidebar) {
      setOpen(true);
    }
  };

  const pages = [
    {
      title: "Materials",
      icon: <FindInPageIcon />,
      children: [
        {
          title: "User Materials",
          href: "/userMaterial",
        },
      ],
    },
    {
      title: "Materials Settings",
      icon: <SettingsIcon />,
      children: [
        {
          title: "Materials",
          href: "/materials",
        },
        {
          title: "Status",
          href: "/status",
        },
        {
          title: "Brands",
          href: "/brand",
        },
        {
          title: "Types",
          href: "/type",
        },
        // {
        //   title: 'Image',
        //   href: '/contents/photo',
        // },
        {
          title: "Departments",
          href: "/department",
        },
        {
          title: "Locations",
          href: "/locations",
        },
        {
          title: "Modeles",
          href: "/modeles",
        },
      ],
    },
    {
      title: "Requests Settings",
      icon: <RequestIcon />,
      children: [
        {
          title: "Requests",
          href: "/requests",
        },
        {
          title: "Motifs",
          href: "/motif",
        },
        {
          title: "Reject Motifs",
          href: "/rejectMotif",
        },
      ],
    },
    {
      title: "Users Settings",
      icon: <PeopleIcon />,
      children: [
        {
          title: "Inventory users",
          href: "/users",
          roleName: "users_list",
        },
      ],
    },
    {
      title: "Settings",
      icon: <SettingsIcon />,
      children: [
        {
          title: "Admin Roles",
          href: "/settings/roles",
        },
        // {
        //   title: 'Admin Permissions',
        //   href: '/settings/permissions',
        // },
        {
          title: "Admin settings",
          href: "/settings/update-password",
        },
      ],
    },
    {
      title: "User Material History",
      href: `/userMaterial/view/${user.data._id}`,
      icon: <HistoryIcon />,
      isForUser: true,
    },
  ];
  // {
  //   title: 'Admin Roles',
  //   href: '/settings/roles',
  // },
  // {
  //   title: 'Notifications',
  //   icon: <NotificationsActiveIcon />,
  //   children: [],
  // },
  /* if(!user) {
     return <Loading/>
   }*/
  //const token =localStorage.getItem('token')

  const filteredPagesByUserRole =
    user?.data?.role?.label === "USER" && user?.data?.role?.label !== undefined
      ? pages.filter((item) => {
        return item.isForUser === true;
      })
      : pages.filter((item) => {
        return item.isForUser !== true;
      });
  let pagesfiltered = []
  const accessPages = pages.map((item) => {
    if (
      user?.data?.role?.label === "USER" &&
      user?.data?.role?.label !== undefined &&
      (item?.href?.includes("/userMaterial/view/") ||
        item?.href?.includes("*") ||
        item?.href === "/" ||
        item?.href?.includes("/requests/create"))
    ) {
      pagesfiltered.push(item);
    }
  })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getUser())
  }, [user?.data?.role?.label !== undefined])
  const role = localStorage.getItem('role')
  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: openSidebar,
      })}
    >
      <Topbar
        onSidebarOpen={handleSidebarOpen}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openSidebar,
        })}
      />
      <Sidebar
        Click={handleSidebar}
        sidebar={open}
        onClose={handleSidebarOpen}
        open={openSidebar}
        user={user}
        pages={
          user?.data?.role?.label === "USER" || role === 'USER'
            ?
            pagesfiltered
            : pages.filter((item) => {
              return item.isForUser !== true;
            })
        }
        variant={isDesktop ? "temporary" : "permanent"}
      />
      <main
        style={{ paddingLeft: isDesktop ? "0px" : "100px" }}
        className={classes.content}
      >
        {/* {fileDataURL ?
        <p className="img-preview-wrapper">
          {
            <img src={fileDataURL} alt="preview" />
          }
        </p> : null} */}
        <ErrorBoundary>{children}</ErrorBoundary>
        <Footer />
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
