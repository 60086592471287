import React from 'react';
import { Redirect } from 'react-router-dom';
import { useStore } from '../contexts/JWTAuthContext';

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useStore();

  if (!isAuthenticated) {
    return <Redirect to='/login' />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {};

export default AuthGuard;
