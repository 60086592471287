import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../notifier/notifierSlice';
import Slide from '@material-ui/core/Slide';
import axios from '../../../utils/axios';

const url = '/statuses';

// fetch all status from server
export const getAllStatuses = createAsyncThunk(
  'statuses/getAll',
  async ({ page, options, rowsPerPage  }, thunkAPI) => {
    try {
      const fetchedStatuses = await axios.get(
        `${url}/?page=${page}${options}&limit=${rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (fetchedStatuses.status === 200) {
        return fetchedStatuses.data;
      }
      throw new Error(fetchedStatuses.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// fetch one status
export const getSingleStatus = createAsyncThunk(
  'status/getOne',
  async (statusId, thunkAPI) => {
    try {
      const fetchedSingleStatus = await axios.get(`${url}/${statusId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (fetchedSingleStatus.status === 200) {
        return fetchedSingleStatus.data.data;
      }
      throw new Error(fetchedSingleStatus.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// create a new status into options
export const createAStatus = createAsyncThunk(
  'status/create',
  async (data, thunkAPI) => {
    try {
      const createdStatus = await axios.post(
        url,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (createdStatus.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              createdStatus?.data?.message || 'Status is created successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
      }
      setTimeout(() => {
        window.location.href = '/status';
      }, 500);
      return createdStatus.data.data;
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);
// edit => update status
export const editStatus = createAsyncThunk(
  'status/edit',
  async  (data, thunkAPI) => {
    try {
      const updatedStatus = await axios.patch(
        `${url}/${data?.id}`,
          data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (updatedStatus.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              updatedStatus?.data?.message ||
              'The Status is successfully updated',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.href = '/status';
        }, 500);
        return updatedStatus?.data?.data?.doc;
      }
      throw new Error(updatedStatus.status);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

// delete a status
export const deleteStatus = createAsyncThunk(
  'status/delete',
  async (statusId, thunkAPI) => {
    try {
      const deletedStatus = await axios.delete(`${url}/${statusId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (deletedStatus.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: 'Status removed successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
       return deletedStatus.data.data;
      }
      throw new Error(deletedStatus.status);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);


export const getTotalStatuses = createAsyncThunk(
  'statuses/getAll',
  async ({ page, options, rowsPerPage  }, thunkAPI) => {
    try {
      const fetchedStatuses = await axios.get(
        `${url}/?page=${page}${options}&pagination=false`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (fetchedStatuses.status === 200) {
        return fetchedStatuses.data;
      }
      throw new Error(fetchedStatuses.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const initialState = {
  statusData: [],
  meta: null,
  singleStatus: {
    data : [],
    status: 'idle',
    error : null,
  },
};

const statusSlice = createSlice({
  name: 'statusSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //  reducers
    builder
      // get statuses request
      .addCase(getAllStatuses.pending, (state, action) => {
      })
      .addCase(getAllStatuses.fulfilled, (state, action) => {
        state.statusData = action.payload;
      })
      .addCase(getAllStatuses.rejected, (state, action) => {
      })
      // get a status request
      .addCase(getSingleStatus.pending, (state, action) => {
        state.singleStatus.status = 'loading';
      })
      .addCase(getSingleStatus.fulfilled, (state, action) => {
        state.singleStatus.status = 'succeeded';
        state.singleStatus.data = action.payload;
      })
      .addCase(getSingleStatus.rejected, (state, action) => {
        state.singleStatus.status = 'failed';
      })

      // create status request
      .addCase(createAStatus.pending, (state, action) => {
      })
      .addCase(createAStatus.fulfilled, (state, action) => {
      })
      .addCase(createAStatus.rejected, (state, action) => {
      })
      // update status request
      .addCase(editStatus.pending, (state, action) => {})
      .addCase(editStatus.fulfilled, (state, action) => {})
      .addCase(editStatus.rejected, (state, action) => {})
      // delete status request
      .addCase(deleteStatus.pending, (state, action) => {})
      .addCase(deleteStatus.fulfilled, (state, action) => {
      })
      .addCase(deleteStatus.rejected, (state, action) => {});
  },
});

export default statusSlice.reducer;
