import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  CardContent,
} from '@material-ui/core';
import Label from '../Label';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import dayjs from 'dayjs';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useDispatch, useSelector } from 'react-redux';
import { getUrl } from 'redux/slices/extra/showSlice';
import ActionDeleteMany from 'components/ActionDeleteMany/ActionDeleteMany';


const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
    zIndex: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  orange: {
    color: 'red',
  },
  purple: {
    color: 'green',
  },
  actions: {
    justifyContent: 'flex-end',
  },
  hidden: {
    display: 'none',
  },
  listItem: {
    padding: 0,
  },
  customTableContainer: {
    overflowX: 'initial',
  },
  CardButtonDelete: {
    background: 'white',
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    paddingBottom: '0px',
  },
}));

/**
 * Returns an enhanced table
 *
 * options:
 * sortable: true
 * available column types:
 * chip: color[default, primary]
 * boolean: color[default, primary]
 * date: color[default, primary]
 * simpleList: field
 * switch: -soon-
 */
const CustomTable = (props) => {
  const dispatch = useDispatch();
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  useEffect(() => {
    dispatch(getUrl(window.location.href));
  }, []);
  const classes = useStyles();
  const {
    data,
    columns,
    sort,
    handleSortChange,
    ActionComponent,
    tableService,
    pageLink,
    page,
    initializeData,
    rowsPerPage,
    noShow,
    noEdit,
    noDelete,
    noInvite,
    count,
    deleteUrl,
    noAction,
    noDeleteMany,
  } = props;
  const [selected, setSelected] = useState([]);
  const Order = 'asc' | 'desc';
  const handleSelectAll = (event) => {
    let selected;

    if (event.target.checked) {
      selected = data?.data.map((row) => row._id);
    } else {
      selected = [];
    }
    setSelected(selected);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const accessor = (obj, is, value) => {
    if (typeof is == 'string') {
      return accessor(obj, is.split('.'), value);
    } else if (is.length === 1 && value !== undefined) {
      return (obj[is[0]] = value);
    } else if (is.length === 0) {
      return obj;
    } else {
      if (obj[is[0]]) {
        return accessor(obj[is[0]], is.slice(1), value);
      }
      return 'NA';
    }
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const chipType = (col, value) => {
    return (
      <Chip
        size='small'
        color={value === 'In Stock' ? 'secondary' : 'primary'}
        label={value}
      />
    );
  };
  const chipStatus = (col, value) => {
    return (
      <Chip
        size='small'
        style={value === 'pending' ? { background: '#fdd835', color: 'white' } : value === 'accepted' ? { background: 'green', color: 'white' } : { background: 'red', color: 'white' }}
        color={value === 'pending' ? 'secondary' : 'primary'}
        label={value}
      />
    );
  };

  const chipTypeOne = (col, value) => {
    return (
      <Chip
        size='small'
        style={{ background: 'rgb(149 59 249)', color: 'white' }}
        label={value !== null && value !== 'NA' ? value : '*******'}
      />
    );
  };
  const chipTypeTwo = (col, value) => {
    return (
      <Chip
        size='small'
        style={{ background: '#0cb5b5', color: 'white' }}
        label={value !== null && value !== 'NA' ? value : '*******'}
      />
    );
  };

  const chipTypeThree = (col, value) => {
    return (
      <Chip
        size='small'

        label={value !== null && value !== 'NA' ? value : 'Not Received Yet'}
      />
    );
  };
  const chipTypeFour = (col, value) => {
    return (
      <Chip
        size='small'
        style={{ background: '#318CE7', color: 'white' }}
        label={value !== null && value !== 'NA' ? value : '*******'}
      />
    );
  };
  const chipTypeFive = (col, value) => {
    return (
      <Chip
        size='small'
        style={value === 'Yes' ? { background: 'orange', color: 'white' } : value === 'No' ? { background: 'red', color: 'white' } : { background: 'blue', color: 'white' }}
        label={value !== null && value !== 'NA' ? value : '*******'}
      />
    );
  };
  const chipDifferent = (col, value) => {
    return (
      <Chip
        size='small'
        style={{ background: 'rgb(245 148 80)', color: 'white' }}
        label={value}
      />
    );
  };

  const idType = (col, value) => {
    return (
      <Typography
        color="textSecondary"
      >
        <Label>
          {value}
        </Label>
      </Typography>
    );
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const booleanType = (col, value) => {
    const v = Number(value) ? 'In Stock' : 'In use';
    return chipType(col, v);
  };
  const booleanTypeTwo = (col, value) => {
    const v = Number(value) ? 'Yes' : 'No';
    return chipTypeFour(col, v);
  };
  const booleanTypeThree = (col, value) => {
    const v = Number(value) ? 'Yes' : 'No';
    return chipTypeFive(col, v);
  };

  const dateType = (col, value) => {
    const v = dayjs(value).isValid()
      ? dayjs(value)
        .format('DD/MM/YYYY')
        .toString()
      : 'Not Received Yet';
    return chipTypeTwo(col, v);
  };
  const dateTimeType = (col, value) => {
    const v = dayjs(value).isValid()
      ? dayjs(value, 'YYYY/MM/DD HH:mm')
        .format('DD/MM/YYYY HH:mm')
        .toString()
      : '* * * * *';
    return chipDifferent(col, v);
  };
  const simpleListType = (col, value) => {
    return (
      <List>
        {value?.map((v) => (
          <ListItem key={v._id}>
            <Chip
              size='small'
              color='secondary'
              label={typeof v === 'string' ? v : v[col.field]}
            />
          </ListItem>
        ))}
      </List>
    );
  };

  const fieldValue = (col, value) => {
    return value !== null && value !== 'NA' ? value : ' ';
  };

  // rows here
  const renderType = (item, col) => {
    const value = accessor(item, col.accessor);
    switch (col.type) {
      // case 'chip':
      //   return chipType(col, 'value');
      case 'boolean':
        return booleanType(col, value);
      case 'booleanTwo':
        return booleanTypeTwo(col, value);
      case 'booleanThree':
        return booleanTypeThree(col, value);
      case 'date':
        return dateType(col, value);
      case 'dateTime':
        return dateTimeType(col, value);
      case 'simpleList':
        return simpleListType(col, value);
      case 'fieldValue':
        return fieldValue(col, value);
      case 'textChip':
        return chipType(col, value);
      case 'swichChipOne':
        return chipTypeOne(col, value);
      case 'swichChipTwo':
        return chipTypeTwo(col, value);
      case 'chipTypeThree':
        return chipTypeThree(col, value);
      case 'chipTypeFour':
        return chipTypeFour(col, value);
      case 'differentChip':
        return chipDifferent(col, value);
      case 'chipStatus':
        return chipStatus(col, value);

      case 'idType':
        return idType(col, value);
      default:
        return value;
    }
  };

  const { deleteMessage } = useSelector((state) => state.show);

  return (
    <div>
      <div
        style={{ position: 'relative', zIndex: '0' }}
        className={classes.root}
      >
        <TableContainer style={{ maxHeight: '700px' }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={selected.length === data?.data?.length}
                    color='primary'
                    indeterminate={
                      selected?.length > 0 && selected?.length < data?.data?.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {columns.map(
                  (col) =>
                    col.show && (
                      <TableCell key={col.accessor}>
                        {col.sortable ? (
                          <TableSortLabel
                            active={sort.name === col.name}
                            direction={
                              sort.name === col.name ? sort.order : 'asc'
                            }
                            onClick={() => handleSortChange(col.name)}
                          >
                            {col.label}
                          </TableSortLabel>
                        ) : (
                          <span>{col.label}</span>
                        )}
                      </TableCell>
                    )
                )}
                {!noAction && ActionComponent && <TableCell>Action</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data?.map((item) => (
                <TableRow hover key={item._id}>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={selected.indexOf(item._id) !== -1}
                      color='primary'
                      onChange={(event) => handleSelectOne(event, item._id)}
                      value='true'
                    />
                  </TableCell>
                  {columns.map(
                    (col) =>
                      col.show && (
                        <TableCell
                          key={`${col.accessor}-${item._id}`}
                          style={{ width: col.width }}
                        >
                          {renderType(item, col)}
                        </TableCell>
                      )
                  )}
                  {!noAction && ActionComponent && (
                    <TableCell style={{ width: '6%' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <ActionComponent
                          itemId={item._id}
                          tableService={tableService}
                          pageLink={pageLink}
                          initializeData={initializeData}
                          rowsPerPage={rowsPerPage}
                          noEdit={noEdit}
                          noShow={noShow}
                          noDelete={noDelete}
                          noInvite={noInvite}

                        />
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
        <CardContent className={classes.CardButtonDelete}>
          <ActionDeleteMany
            selectedItems={selected}
            setSelectedItems={setSelected}
            initializeData={initializeData}
            deleteUrl={deleteUrl}
            noDeleteMany={noDeleteMany}
          />
        </CardContent>
      </div>
    </div>
  );
};

CustomTable.propTypes = {
  data: PropTypes.object,
  columns: PropTypes.array,
  sort: PropTypes.object,
  handleSortChange: PropTypes.func,
  ActionComponent: PropTypes.elementType,
};

export default CustomTable;
