import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import Delete from '../Action/Delete';
import Invite from '../Action/Invite';
import { rolePermissionsList, permissionsList, isEditTrue, isShowTrue, isDeleteTrue, isInviteTrue } from "../../utils/acl/aclRouter";
import { useSelector } from 'react-redux';

const Action = (props) => {
  const isUserPage = useLocation().pathname === "/users"
  const {
    itemId,
    tableService,
    pageLink,
    initializeData,
    rowsPerPage,
    noShow,
    noEdit,
    noDelete,
    noInvite,
    ...rest
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openInvitaion, setopenInvitaion] = React.useState(false);
  const [isEditPermission, setIsEditPermission] = React.useState(false);
  const [isShowPermission, setIsShowPermission] = React.useState(false);
  const [isDeletePermission, setIsDeletePermission] = React.useState(false);
  const [isInvitePermission, setisInvitePermission] = React.useState(false);


  const { user } = useSelector((state) => state.auth)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setOpenDelete(!openDelete);
  };
  const handleInvitation = () => {
    setopenInvitaion(!openInvitaion);
  };

  let allPrmArr = [];
  allPrmArr = rolePermissionsList(user?.role?.permissions).concat(permissionsList(user?.permissions));
  const route = window.location.pathname;
  const pageName = route.includes('settings') ? route.split('/')[2].toUpperCase() : route.split('/')[1].toUpperCase();

  React.useEffect(() => {
    if (isEditTrue(allPrmArr, pageName, user)) {
      setIsEditPermission(true);
    }
    if (isShowTrue(allPrmArr, pageName, user)) {
      setIsShowPermission(true);
    }
    if (isDeleteTrue(allPrmArr, pageName, user)) {
      setIsDeletePermission(true)
    }
    if (isInviteTrue(allPrmArr, pageName, user)) {
      setisInvitePermission(true)
    }
  }, [allPrmArr]);

  return (
    <div>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '20ch',
          },
        }}
      >
        {!noShow && user && isShowPermission && <MenuItem component={Link} to={`${pageLink}/view/${itemId}`}>
          Show
        </MenuItem>}
        {!noEdit && user && isEditPermission &&
          <MenuItem component={Link} to={`${pageLink}/edit/${itemId}`}>
            Edit
          </MenuItem>}
        {!noDelete && user && isDeletePermission && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
        {!noInvite && user && isInvitePermission && isUserPage && <MenuItem onClick={handleInvitation}>Send Invitation</MenuItem>}
      </Menu>
      <Delete
        open={openDelete}
        handleDelete={handleDelete}
        itemId={itemId}
        service={tableService}
        pageLink={pageLink}
        rowsPerPage={rowsPerPage}
        initializeData={initializeData}
      />
      <Invite
        open={openInvitaion}
        handleInvitation={handleInvitation}
        itemId={itemId}
        service={tableService}
        pageLink={pageLink}
        rowsPerPage={rowsPerPage}
        initializeData={initializeData}
        closeActions={handleClose}
      />
    </div>
  );
};

Action.propTypes = {
  className: PropTypes.string,
  itemId: PropTypes.number,
  noShow: PropTypes.bool,
};

export default Action;
