import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Toolbar from '../../components/Toolbar/Toolbar';
import Table from '../Table/TableShow';
import optionsParser from '../../helpers/optionsParser';
import Loading from '../../components/Loading/Loading';
import Collapse from '@material-ui/core/Collapse';
import Columns from '../../components/Columns';
import Filter from '../../components/Filter/Filter';
import { options } from 'views/Dashboard/components/LatestSales/chart';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  }
}));

const TableList = (props) => {
  const {
    columnsList,
    fieldFilterable,
    url,
    exportUrl,
    pageLink,
    title,
    searchMessage,
    baseUrl,
    data,
    deleteUrl,
    removeAdd,
    noExport,
    noShow,
    noEdit,
    noDelete,
    noInvite,
    dispatchFunction,
    noAction,
    noSearch,
    noColumns,
    noDeleteMany,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const [search, searchChange] = useState('');
  const [filters, filtersChange] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({ accessor: 'id', order: 'desc' });
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getData = () => {
    const options = optionsParser(search, filters, sort);
    dispatch(dispatchFunction({ page, options: options, rowsPerPage }));
  };
  useEffect(() => {
    getData();
  }, [page, sort, rowsPerPage]);

  useEffect(() => {
    if (search.length) {
      const options = optionsParser(search, null, sort);
      setIsSearch(true);
      setPage(1);
      dispatch(dispatchFunction({ page, options: options, rowsPerPage }));
    }
    if (Object.keys(filters).length) {
      const options = optionsParser(null, filters, sort);
      setPage(1);
      dispatch(dispatchFunction({ page: page, options: options, rowsPerPage }));
    }
  }, [filters, search]);
  useEffect(() => {
    if (!search && isSearch) {
      setIsSearch(false);
      setPage(1);
      getData();
    }
  }, [search]);
  useEffect(() => {
    if (!search && isSearch) {
      setIsSearch(false);
      setPage(1);
      getData();
    }
  }, [search]);
  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };
  const toggleColumns = () => {
    setColumnsOpen(!columnsOpen);
  };
  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === 'asc' ? 'desc' : 'asc',
    });
  };

  const handlePageChange = (event, page) => {
    setPage(page + 1);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const classes = useStyles();
  if (!data) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <Toolbar
        toggleFilters={toggleFilters}
        toggleColumns={toggleColumns}
        searchChange={searchChange}
        data={data}
        title={title}
        filters={filters}
        pageLink={pageLink}
        searchMessage={searchMessage}
        removeAdd={removeAdd}
        noExport={noExport}
        noSearch={noSearch}
        noColumns={noColumns}
        initializeData={() =>
          dispatch(
            dispatchFunction({ page, options: '', rowsPerPage })
          )
        }
      />
      <div className={classes.content}>
        <Collapse in={columnsOpen}>
          <Columns columnsChange={columnsChange} columns={columns} />
        </Collapse>
        <Collapse in={filtersOpen}>
          <Filter
            fields={fieldFilterable}
            values={filters}
            filtersChange={filtersChange}
            initializeData={() =>
              dispatch(
                dispatchFunction({ page, options: '', rowsPerPage })
              )
            }
          />
        </Collapse>
        <Table
          columns={columns}
          data={data}
          handleSortChange={handleSortChange}
          sort={sort}
          handlePageChange={handlePageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          handleRowsPerPageChange={handleRowsPerPageChange}
          // tableService={{
          //   base_url: `${baseUrl}`,
          // }}
          pageLink={pageLink}
          noShow={noShow}
          noAction={noAction}
          noDelete={noDelete}
          noInvite={noInvite}
          noDeleteMany={noDeleteMany}
          noEdit={noEdit}
          deleteUrl={deleteUrl}
          initializeData={() =>
            dispatch(
              dispatchFunction({ page: page, options: '', rowsPerPage })
            )
          } />
      </div>
    </div>
  );
};

export default TableList;
