import axios from './axios';
import optionsParser from 'helpers/optionsParser';
import { api_get } from "../utils/Api";

export const getMaterials = async (search, prevData, page) => {
  const result = await axios.get(
    `materiels?pagination=false&available=1&toDeliver=true&search=${search}`
  );
  return {
    options: [...result.data.data],
    // hasMore: result.data.meta.page !== result.data.meta.page,
    // additional: {
    //   page: result.data.meta.page + 1,
    // },
  };
};
export const getLocations = async (search, prevData, page) => {
  const result = await axios.get(
    `locations?pagination=false&search=${search}`
  );
  return {
    options: [...result.data.data],
    // hasMore: result.meta.page !== result.meta.page,
    // additional: {
    //   page: result.meta.page + 1,
    // },´
  };
};
export const getModeles = async (search, prevData, page) => {
  const result = await axios.get(
    `models?pagination=false&search=${search}`
  );
  return {
    options: [...result.data.data],
    // hasMore: result.meta.page !== result.meta.page,
    // additional: {
    //   page: result.meta.page + 1,
    // },´
  };
};
export const getUserMaterialId = async (materialId) => {
  const result = await axios.get(
    `user-materiels?materiel_id=${materialId}`
  );

  return result.data?.data[0]?._id
  // hasMore: result.meta.page !== result.meta.page,
  // additional: {
  //   page: result.meta.page + 1,
  // },´

};

export const getTypes = async (search, prevData, page) => {
  const result = await axios.get(
    `types?pagination=false&search=${search}`
  );
  return {
    options: [...result.data.data],
    // hasMore: result.meta.page !== result.meta.page,
    // additional: {
    //   page: result.meta.page + 1,
    // },´
  };
};
export const getSpecificTypes = async (search, prevData, page) => {
  const result = await axios.get(
    `types?toShow=1&pagination=false&search=${search}`
  );
  return {
    options: [...result.data.data],
    // hasMore: result.meta.page !== result.meta.page,
    // additional: {
    //   page: result.meta.page + 1,
    // },´
  };
};
export const getMotifs = async (search, prevData, page) => {
  const result = await axios.get(
    `motifs?pagination=false&search=${search}`
  );
  return {
    options: [...result.data.data],
  };
};
export const getRejectMotifs = async (search, prevData, page) => {
  const result = await axios.get(
    `rejectMotifs?pagination=false&search=${search}`
  );
  return {
    options: [...result.data.data],
  };
};

export const getStatus = async (search, prevData, page) => {
  const result = await axios.get(
    `statuses?pagination=false&search=${search}`
  );
  return {
    options: [...result.data.data],
    // hasMore: result.meta.page !== result.meta.page,
    // additional: {
    //   page: result.meta.page + 1,
    // },´
  };
};
export const getRequestStatus = async (search, prevData, page) => {
  const data = [{ _id: "2", name: "pending" }, { _id: "3", name: "accepted" }, { _id: "4", name: "rejected" }]
  return {
    options: [...data],
    // hasMore: result.meta.page !== result.meta.page,
    // additional: {
    //   page: result.meta.page + 1,
    // },´
  };
};

export const getBrand = async (search, prevData, page) => {
  const result = await axios.get(
    `brands?pagination=false&search=${search}`
  );
  return {
    options: [...result.data.data],
    // hasMore: result.meta.page !== result.meta.page,
    // additional: {
    //   page: result.meta.page + 1,
    // },´
  };
};


export const getUsers = async (search, prevData, page, event) => {
  const result = await axios.get(
    `users?pagination=false&search=${search}`
  );
  return {
    options: [...result.data.data],
    // hasMore: result.meta.page !== result.meta.page,
    // additional: {
    //   page: result.meta.page + 1,
    // },
  };
};

export const getDepartments = async (search, prevData, page) => {
  const result = await axios.get(
    `departments?pagination=false&search=${search}`
  );
  return {
    options: [...result.data.data],
    // hasMore: result.meta.page !== result.meta.page,
    // additional: {
    //   page: result.meta.page + 1,
    // },
  };
};

export const getPermissions = async (search, prevData, page) => {
  const result = await axios.get(
    `permissions?pagination=false&search=${search}`
  );
  return {
    options: [...result.data.data],
    // hasMore: result.meta.page !== result.meta.page,
    // additional: {
    //   page: result.meta.page + 1,
    // },
  };
};

export const getRoles = async (search, prevData, page) => {
  const result = await axios.get(
    `roles?pagination=false&search=${search}`
  );
  const filtredData = result.data.data.filter((item) => item.label !== "SUPER_ADMIN");
  return {
    options: [...filtredData],
    // hasMore: result.meta.page !== result.meta.page,
    // additional: {
    //   page: result.meta.page + 1,
    // },
  };
};