import { configureStore, applyMiddleware } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import materialsReducer from '../slices/materials/materialsSlice';
import statusReducer from '../slices/status/statusSlice';
import brandReducer from '../slices/brand/brandSlice';
import motifReducer from '../slices/motif/motifSlice';
import rejectMotifReducer from '../slices/rejectMotif/rejectMotif';
import requestsReducer from '../slices/request/requestSlice';
import typeReducer from '../slices/type/typeSlice';
import photoReducer from '../slices/photo/photoSlice';
import showReducer from '../slices/extra/showSlice';
import authReducer from '../slices/auth/userSlice';
import departmentReducer from '../slices/department/departmentSlice';
import locationReducer from '../slices/location/locationSlice';
import adminRoleReducer from '../slices/role/adminRoleSlice';
import permissionReducer from '../slices/permission/permissionSlice';
import modeleReducer from '../slices/modele/modeleSlice';
import userMaterialReducer from '../slices/userMaterial/userMaterialSlice';
import notifierReducer from "../slices/notifier/notifierSlice";
import deleteReducer from "../slices/actions/deleteSlices";
import exportReducer from "../slices/actions/exportSlices";

const store = configureStore({
  reducer: {
    materials: materialsReducer,
    requests: requestsReducer,
    status: statusReducer,
    brand: brandReducer,
    motif: motifReducer,
    rejectMotif: rejectMotifReducer,
    type: typeReducer,
    show: showReducer,
    auth: authReducer,
    department: departmentReducer,
    location: locationReducer,
    modele: modeleReducer,
    userMaterial: userMaterialReducer,
    notifier: notifierReducer,
    role: adminRoleReducer,
    permission: permissionReducer,
    deleteAction: deleteReducer,
    exportAction: exportReducer,
  },
});
export default store;
