import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { enqueueSnackbar } from '../notifier/notifierSlice';
import Slide from '@material-ui/core/Slide';

const initialState = {
    adminRoles: [],
    status: 'idle',
    error: null,
    adminRole: {
        data: [],
        status: 'idle',
        error: null,
    },
    meta: null,

};

export const fetchAdminRoles = createAsyncThunk('adminRoles/fetchAdminRoles',
    async ({ page, options, rowsPerPage }) => {
    let data;
    try {
        const response = await axios.get(`/roles?page=${page}${options}&limit=${rowsPerPage}`);

        data = await response.data;
        if ((response.status = 200)) {
            return data;
        }
        throw new Error(response.statusText);
    } catch (err) {
        console.log(err);
        return Promise.reject(err.message ? err.message : data?.message);
    }
});

export const fetchOneAdminRole = createAsyncThunk(
    "adminRoles/fetchOneadminRole",
    async (id) => {
      let data;
      try {
        const response = await axios.get(`/roles/${id}`);
        data = await response.data;
        if ((response.status = 200)) {
          return data;
        }
        throw new Error(response.statusText);
      } catch (err) {
        console.log(err);
        return Promise.reject(err.message ? err.message : data?.message);
      }
});

export const addNewAdminRole = createAsyncThunk(
    "adminRoles/addNewAdminRole",
    async (query, thunkAPI) => {
      const { requestValues } = query;
      let data;
      try {
        const response = await axios.post(`roles`, requestValues);
        data = await response.data;
        if (response.status === 200) {
          thunkAPI.dispatch(
            enqueueSnackbar({
              message: data.message,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                TransitionComponent: Slide,
              },
            })
          );
          setTimeout(() => {
            window.location.href = "/settings/roles";
          }, 2000);
          return data.payload;
        }
        throw new Error(response.statusText);
      } catch (err) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: err,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        console.log(err);
        return Promise.reject(err.message ? err.message : data?.message);
      }
});
  
export const editAdminRole = createAsyncThunk(
    "adminRoles/editAdminRole",
    async (query, thunkAPI) => {
      const { id, requestValues } = query;
      let data;
      try {
        const response = await axios.patch(`roles/${id}`, requestValues);
        data = await response.data;
        if (response.status === 200) {
          thunkAPI.dispatch(
            enqueueSnackbar({
              message: data.message,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                TransitionComponent: Slide,
              },
            })
          );
          setTimeout(() => {
            window.location.href = "/settings/roles";
          }, 2000);
          return data.payload;
        }
        throw new Error(response.statusText);
      } catch (err) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: err,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        console.log(err);
        return Promise.reject(err.message ? err.message : data?.message);
      }
});
export const deleteRole = createAsyncThunk(
  'adminRole/delete',
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`roles/${id}`);
      if (response.status === 200) {
        console.log(response);
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              response?.data?.message || 'Role is removed successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        return response.data.data;
      }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

const adminRoleSlice = createSlice({
    name: 'adminRoleSlice',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAdminRoles.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchAdminRoles.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.adminRoles = action.payload;
        },
        [fetchAdminRoles.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [fetchOneAdminRole.pending]: (state, action) => {
            state.adminRole.status = 'loading';
        },
        [fetchOneAdminRole.fulfilled]: (state, action) => {
            state.adminRole.status = 'succeeded';
            state.adminRole.data = action.payload;
        },
        [fetchOneAdminRole.rejected]: (state, action) => {
            state.adminRole.status = 'failed';
            state.adminRole.error = action.payload;
        },
        [addNewAdminRole.pending]: (state, action) => {
            state.adminRole.status = 'loading';
        },
        [addNewAdminRole.fulfilled]: (state, action) => {
            state.adminRole.status = 'succeeded';
            state.adminRole.data = action.payload.data;
        },
        [addNewAdminRole.rejected]: (state, action) => {
            state.adminRole.status = 'failed';
            state.adminRole.error = action.payload;
        },
        [editAdminRole.pending]: (state, action) => {
            state.adminRole.status = 'loading';
        },
        [editAdminRole.fulfilled]: (state, action) => {
            state.adminRole.status = 'succeeded';
            state.adminRole.data = action.payload.data;
        },
        [editAdminRole.rejected]: (state, action) => {
            state.adminRole.status = 'failed';
            state.adminRole.error = action.payload;
        },
    },
});

export default adminRoleSlice.reducer;