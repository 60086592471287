import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import SnackBar from '../SnackBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TextField, Box } from '@material-ui/core';
import { deleteBrand } from 'redux/slices/brand/brandSlice';
import { deleteMotif } from 'redux/slices/motif/motifSlice';
import { deleterejectMotif } from 'redux/slices/rejectMotif/rejectMotif';
import { deleteType } from 'redux/slices/type/typeSlice';
import { deleteStatus } from 'redux/slices/status/statusSlice';
import { deletePhoto } from 'redux/slices/photo/photoSlice';
import { deleteMaterial } from 'redux/slices/materials/materialsSlice';
import { removeDepartment } from 'redux/slices/department/departmentSlice';
import { removeUserMaterial } from 'redux/slices/userMaterial/userMaterialSlice';
import { deleteUser } from 'redux/slices/auth/userSlice';
import { removeItem } from 'redux/slices/extra/showSlice';
import { deleteLocation } from 'redux/slices/location/locationSlice';
import { deleteModele } from 'redux/slices/modele/modeleSlice';
import { deleteRole } from 'redux/slices/role/adminRoleSlice';
import { deleteRequest } from 'redux/slices/request/requestSlice';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
}));
const Delete = (props) => {
  const dispatch = useDispatch();
  const whichUrl = useSelector((state) => state.show.showSubstance);

  const {
    className,
    handleDelete,
    open,
    itemId,
    service,
    pageLink,
    restore,
    initializeData,
    rowsPerPage,
    replace,
    ...rest
  } = props;
  const classes = useStyles();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [eventId, setEventId] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [severity, setAlertSeverity] = React.useState('success');

  const handleCheckChange = () => {
    setChecked(!checked);
  };

  const handleClose = () => {
    setOpenAlert(false);
    setChecked(false);
    setEventId('');
  };

  const deleteHandler = () => {
    const deletedBasedUrl = whichUrl.toString().slice(-20);
    if (deletedBasedUrl.includes('material')) {
      dispatch(deleteMaterial(itemId));
    }
    if (deletedBasedUrl.includes('request')) {
      dispatch(deleteRequest(itemId));
    }
    if (deletedBasedUrl.includes('status')) {
      dispatch(deleteStatus(itemId));
    }
    if (deletedBasedUrl.includes('brand')) {
      dispatch(deleteBrand(itemId));
    }
    if (deletedBasedUrl.includes('motif')) {
      dispatch(deleteMotif(itemId));
    }
    if (deletedBasedUrl.includes('rejectMotif')) {
      dispatch(deleterejectMotif(itemId));
    }
    if (deletedBasedUrl.includes('type')) {
      dispatch(deleteType(itemId));
    }
    if (deletedBasedUrl.includes('photo')) {
      dispatch(deletePhoto(itemId));
    }
    if (deletedBasedUrl.includes('department')) {
      dispatch(removeDepartment(itemId));
    }
    if (deletedBasedUrl.includes('userMaterial')) {
      dispatch(removeUserMaterial(itemId));
    }
    if (deletedBasedUrl.includes('users')) {
      dispatch(deleteUser(itemId));
    }
    if(deletedBasedUrl.includes('location')) {
      dispatch(deleteLocation(itemId))
    }
    if(deletedBasedUrl.includes('modele')){
      dispatch(deleteModele(itemId))
    }
    if(deletedBasedUrl.includes('roles')){
      dispatch(deleteRole(itemId))
    }
    handleDelete();
    setTimeout(() => {
      initializeData();
    }, 500);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        open={open}
        onClose={handleDelete}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure to continue deleting?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color='primary'>
            Cancel
          </Button>
          <Button onClick={deleteHandler} color='primary' autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBar
        open={openAlert}
        handleClose={handleClose}
        message={message}
        severity={severity}
      />
    </div>
  );
};


Delete.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  handleDelete: PropTypes.func,
  itemId: PropTypes.number,
};

export default Delete;
