import { Slide } from '@material-ui/core';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../notifier/notifierSlice';

import axios from './../../../utils/axios';

// urls:
const url = '/brands';

// fetch all brands from server
export const getAllBrands = createAsyncThunk(
  'brands/getAll',
  async ({ page, options, rowsPerPage }, thunkAPI) => {
    try {
      const fetchedBrands = await axios.get(url + `?page=${page}${options}&limit=${rowsPerPage}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (fetchedBrands.status === 200) {
        return fetchedBrands.data;
      }
      throw new Error(fetchedBrands.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);


// show single brand
export const getSingleBrand = createAsyncThunk(
  'brand/getOne',
  async (brandId, thunkAPI) => {
    try {
      const fetchedSingleBrand = await axios.get(`${url}/${brandId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (fetchedSingleBrand.status === 200) {
        return fetchedSingleBrand.data.data;
      }
      throw new Error(fetchedSingleBrand.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// create a new brand into options
export const createBrand = createAsyncThunk(
  'brand/create',
  async (brandToCreate, thunkAPI) => {
    try {
      const createdBrand = await axios.post(
        url,
        {
          name: brandToCreate,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (createdBrand.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              createdBrand?.data?.message || 'Brand is created successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.pathname = '/brand';
        }, 500);
        return createdBrand.data.data;
      }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);
// edit => update brand
export const editBrand = createAsyncThunk(
  'brand/edit',
  async (updateBrand, thunkAPI) => {
    try {
      const updatedBrand = await axios.patch(
        `${url}/${updateBrand?.id}`,
        {
          name: updateBrand?.brand,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (updatedBrand.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              updatedBrand?.data?.message || 'Brand is updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
      }
      setTimeout(() => {
        window.location.pathname = '/brand';
      }, 500);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

// delete a brand
export const deleteBrand = createAsyncThunk(
  'brand/delete',
  async (brandId, thunkAPI) => {
    try {
      const deletedBrand = await axios.delete(`${url}/${brandId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (deletedBrand.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              deletedBrand?.data?.message || 'Brand is removed successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        return deletedBrand.data.data;
      }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

export const getTotalBrands = createAsyncThunk(
  'brands/getAll',
  async ({ page, options, rowsPerPage }, thunkAPI) => {
    try {
      const fetchedBrands = await axios.get(url + `?page=${page}${options}&pagination=false`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (fetchedBrands.status === 200) {
        return fetchedBrands.data;
      }
      throw new Error(fetchedBrands.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  status: "idle",
  brandData: [],
  singleBrand: null,
  meta: null,
};

const brandSlice = createSlice({
  name: 'brandSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //  reducers
    builder
      // get brand request
      .addCase(getAllBrands.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllBrands.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.brandData = action.payload;
      })
      .addCase(getAllBrands.rejected, (state, action) => {
        state.status = "failed";
      })
      // one brand request
      .addCase(getSingleBrand.pending, (state, action) => {
      })
      .addCase(getSingleBrand.fulfilled, (state, action) => {
        state.singleBrand = action.payload;
      })
      .addCase(getSingleBrand.rejected, (state, action) => { })
      // create brand request
      .addCase(createBrand.pending, (state, action) => {
      })
      .addCase(createBrand.fulfilled, (state, action) => {
      })
      .addCase(createBrand.rejected, (state, action) => {
      })
      // edit brand request
      .addCase(editBrand.pending, (state, action) => { })
      .addCase(editBrand.fulfilled, (state, action) => { })
      .addCase(editBrand.rejected, (state, action) => { })
      // remove brand request
      .addCase(deleteBrand.pending, (state, action) => { })
      .addCase(deleteBrand.fulfilled, (state, action) => {
      })
      .addCase(deleteBrand.rejected, (state, action) => { });
  },
});

export default brandSlice.reducer;
