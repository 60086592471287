import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import SnackBar from "../SnackBar";
import { Button, CardContent } from "@material-ui/core";
import { DeleteRounded } from "@material-ui/icons";
import { CloudUpload } from "@material-ui/icons";
import { useStore } from "../../contexts/JWTAuthContext";
import { checkPermission } from "../../utils/acl/aclRouter";
import { actionDeleteMany } from "../../redux/slices/actions/deleteSlices";
import { actionExportMany } from "../../redux/slices/actions/exportSlices";

import { useDispatch, useSelector } from "react-redux";
import { rolePermissionsList, permissionsList, isDeleteTrue } from "../../utils/acl/aclRouter";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    style: {
        color: 'red',
        fontSize: 20
    },
}));

const ActionDeleteMany = (props) => {
    const { pageLink, baseUrl, noDeleteMany, noDelete, noCheck, selectedItems, setSelectedItems, rowsPerPage, initializeData, keyValue, deleteUrl, setOpenDelete, ...rest } = props;
    const { user } = useSelector((state) => state.auth)
    const routeDelete = window.location.pathname + '/delete';
    const [message, setAlertMessage] = useState('');
    const [severity, setAlertSeverity] = useState('success');
    const [openModal, setOpenModal] = useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [isDeletePermission, setIsDeletePermission] = React.useState(false);
    const { deleteMany } = useSelector((state) => state.deleteAction);
    const { exportMany } = useSelector((state) => state.exportAction);


    const dispatch = useDispatch();

    const handleModalClose = () => {
        setOpenModal(!openModal);
        if (selectedItems.length == 0) {
            setOpenModal(false);
        }
    };
    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    const handleDeleteItems = () => {
        dispatch(actionDeleteMany({ deleteUrl: deleteUrl, selectedItems: selectedItems }));
        setTimeout(() => {
            initializeData();
        }, 1000);
    };
    const handleExportItems = () => {
        dispatch(actionExportMany({ deleteUrl: deleteUrl, selectedItems: selectedItems }));
        setTimeout(() => {
            initializeData();
        }, 1000);
    };

    useEffect(() => {
        if (deleteMany.status == 'succeeded') {
            setSelectedItems([]);
            setOpenModal(false);
        }
    }, [deleteMany.status])

    useEffect(() => {
        if (exportMany.status == 'succeeded') {
            setSelectedItems([]);
        }
    }, [exportMany.status])
    let allPrmArr = [];
    allPrmArr = rolePermissionsList(user?.role?.permissions).concat(permissionsList(user?.permissions));
    const route = window.location.pathname;
    const pageName = route.includes('settings') ? route.split('/')[2].toUpperCase() : route.split('/')[1].toUpperCase();

    React.useEffect(() => {
        if (isDeleteTrue(allPrmArr, pageName, user)) {
            setIsDeletePermission(true)
        }
    }, [allPrmArr]);

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {selectedItems.length > 0 && !noDeleteMany && user && isDeletePermission && (
                <CardContent display="flex" gap="20px">
                    Many items to :
                    <Button
                        color="primary"
                        size="large"
                        onClick={handleModalClose}
                        startIcon={<DeleteRounded />}>
                        Delete
                    </Button>
                    <Button
                        color="primary"
                        size="large"
                        onClick={() => handleExportItems()}
                        startIcon={<CloudUpload />}>
                        Export
                    </Button>
                </CardContent>)}
            <Dialog
                open={openModal}
                onClose={handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Delete item(s) Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete these <span className={classes.style}>{selectedItems.length}</span> item(s) ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDeleteItems()} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackBar
                open={openAlert}
                message={message}
                severity={severity}
                handleClose={handleCloseAlert}
                autoHideDuration={6000}
            />
        </div>
    );
};

export default ActionDeleteMany;
