import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { getAllMateriels } from 'redux/slices/materials/materialsSlice';
import TableList from "components/List/TableList";


const columnsList = [
    {
      accessor: '_id',
      name: 'id',
      label: 'Id',
      width: '20%',
      show: true,
      sortable: true,
      type: 'idType',
    },
    {
      accessor: 'name',
      label: 'Name',
      width: '20%',
      show: true,
      type: 'swichChipTwo',
    },
    {
      accessor: 'serial_number',
      label: 'Serial Number',
      width: '16%',
      show: true,
      type: 'fieldValue',
      //type: 'swichChipOne',
    },
    {
      accessor: 'note',
      label: 'Note',
      width: '16%',
      show: true,
      type: 'fieldValue',
    },
    {
      accessor: 'available',
      type: 'boolean',
      color: 'primary',
      width: '16%',
      label: 'Available',
      show: true,
    },
    {
      accessor: 'date_of_purchase',
      label: 'Date of purchase',
      width: '16%',
      show: true,
      type: 'date',
    },
    {
      accessor: 'Status.name',
      label: 'Status',
      width: '16%',
      show: true,
      type: 'swichChipOne',
    },
  ];
const fieldFilterable = [
    {
      name: 'available',
      label: 'Available',
      type: 'availableDropdown',
    },
    {
      name: 'brand_id',
      label: 'Brand',
      type:'brandAutocomplete',
    },
    {
      name: 'status_id',
      label: 'Status',
      type: 'statusAutocomplete',
    },
    {
      name: 'type_id',
      label: 'Type',
      type: 'typeAutocomplete',
    },
    {
      name: 'delivred_at',
      label: 'Date by range',
      type: 'dateRange',
    },
  ];

const MaterialList = (props) => {

const { materials } = useSelector((state) => state.materials);

return (
    <TableList
      dispatchFunction={getAllMateriels}
      data={materials}
      columnsList={columnsList}
      fieldFilterable={fieldFilterable}
      title={'Materials'}
      pageLink={'/materials'}
      searchMessage={"Search (Name, Serial Number)"}
      deleteUrl={'materiels'}
    />
  );
};

export default MaterialList;
