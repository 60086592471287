import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { AuthProvider } from './contexts/JWTAuthContext';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import routes, { RenderRoutes } from './Routes';
import Notifier from './layouts/Notifier';
import { SnackbarProvider } from 'notistack';
import { MaterialEdit } from 'views';

const browserHistory = createBrowserHistory();
validate.validators = {
  ...validate.validators,
  ...validators,
};

if (document.referrer.includes('')) {
  const url = new URL(window.location);
  window.history.pushState({}, '', url);
}
export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={12}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Notifier />
          <BrowserRouter history={browserHistory}>
            <AuthProvider>
              <RenderRoutes routes={routes} />
            </AuthProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}
