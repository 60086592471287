import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import theme from '../../../theme';
import {AsyncPaginate} from 'react-select-async-paginate';

const useStyles = makeStyles((theme) => ({
  root: {},
  menuItem: {
    textTransform: 'capitalize',
  },
}));

const AvailableFilter = (props) => {
  const { className, filtersChange, field, values, ...rest } = props;
  const classes = useStyles();

  const availableOptions = [
    { label: "In Stock", _id: true },
    { label: "In Use", _id: false },
  ];  
  // const statusOptions = [
  //   { label: "pending", _id: "pending" },
  //   { label: "accepted", _id: "accepted" },
  //   { label: "rejected", _id: "rejected" },
  // ];  
  const handleChange = (value) => {
    filtersChange({
      [field.name]: value,
    });
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <AsyncPaginate
        options={availableOptions}
        onChange={handleChange}
        getOptionLabel={(option) => option.label }
        getOptionValue={(option) => option._id}
        placeholder={field.label}
        isClearable={false}
        additional={{
          page: 1,
        }}
        value={values[field.name] || null}
        styles={{
          placeholder: (base) => ({
            ...base,
            color: '#000',
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily,
          }),
        }}
        menuPortalTarget={document.querySelector('body')}
      />
    </div>
  );
};

AvailableFilter.propTypes = {
  className: PropTypes.string,
  filtersChange: PropTypes.func,
  field: PropTypes.object,
  values: PropTypes.object,
  handleChange: PropTypes.func,
};

export default AvailableFilter;