import React , { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import optionsParser from '../../../helpers/optionsParser';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import axios from './../../../utils/axios';
import  {api_get} from '../../../utils/Api.js';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const StatusAutocomplete = (props) => {
  const {className, filtersChange, field, values, ...rest} = props;
  const classes = useStyles();
  const [statusInput, setStatusInput] = useState('');
  
  const getStatus = async (search, prevData, page) => {
    const result = await axios.get(`statuses?pagination=false&search=${search}`);
    return {
      options: [...result.data.data],
      // hasMore: result.meta.current_page !== result.meta.last_page,
      // additional: {
      //   page: result.meta.current_page + 1,
      // },
    };
  };
  const handleChange = (value) => {
    filtersChange({
      [field.name]: value,
    });
  };
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <AsyncPaginate
        loadOptions={getStatus}
        onChange={handleChange}
        getOptionLabel={(option) => option.name }
        getOptionValue={(option) => option._id}
        placeholder={field.label}
        isClearable={false}
        inputValue={statusInput}
        onInputChange={setStatusInput}
        additional={{
          page: 1,
        }}
        value={values[field.name] || null}
        styles={{
          placeholder: (base) => ({
            ...base,
            color: '#000',
            fontSize: theme.typography.fontSize,
            fontFamily: theme.typography.fontFamily,
          }),
        }}
        menuPortalTarget={document.querySelector('body')}
      />
    </div>
  );
};

StatusAutocomplete.propTypes = {
  className: PropTypes.string,
  filtersChange: PropTypes.func,
  field: PropTypes.object,
  values: PropTypes.object,
  handleChange: PropTypes.func,
};

export default StatusAutocomplete;
