import { Slide } from '@material-ui/core';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../notifier/notifierSlice';

import axios from './../../../utils/axios';

// urls:
const url = '/motifs';

// fetch all motifs from server
export const getAllMotifs = createAsyncThunk(
  'motifs/getAll',
  async ({ page, options, rowsPerPage }, thunkAPI) => {
    try {
      const fetchedMotifs = await axios.get(url + `?page=${page}${options}&limit=${rowsPerPage}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (fetchedMotifs.status === 200) {
        return fetchedMotifs.data;
      }
      throw new Error(fetchedMotifs.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);


// show single motif
export const getSingleMotif = createAsyncThunk(
  'motif/getOne',
  async (motifId, thunkAPI) => {
    try {
      const fetchedSingleMotif = await axios.get(`${url}/${motifId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (fetchedSingleMotif.status === 200) {
        return fetchedSingleMotif.data.data;
      }
      throw new Error(fetchedSingleMotif.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// create a new motif into options
export const createMotif = createAsyncThunk(
  'motif/create',
  async (motifToCreate, thunkAPI) => {
    try {
      const createdMotif = await axios.post(
        url,
        {
          name: motifToCreate,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (createdMotif.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              createdMotif?.data?.message || 'Motif is created successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.pathname = '/motif';
        }, 500);
        return createdMotif.data.data;
      }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);
// edit => update motif
export const editMotif = createAsyncThunk(
  'motif/edit',
  async (updateMotif, thunkAPI) => {
    try {
      const updatedMotif = await axios.patch(
        `${url}/${updateMotif?.id}`,
        {
          name: updateMotif?.motif,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (updatedMotif.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              updatedMotif?.data?.message || 'Motif is updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
      }
      setTimeout(() => {
        window.location.pathname = '/motif';
      }, 500);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

// delete a motif
export const deleteMotif = createAsyncThunk(
  'motif/delete',
  async (motifId, thunkAPI) => {
    try {
      const deletedMotif = await axios.delete(`${url}/${motifId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (deletedMotif.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              deletedMotif?.data?.message || 'Motif is removed successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        return deletedMotif.data.data;
      }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

export const getTotalMotifs = createAsyncThunk(
  'motifs/getAll',
  async ({ page, options, rowsPerPage }, thunkAPI) => {
    try {
      const fetchedMotifs = await axios.get(url + `?page=${page}${options}&pagination=false`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (fetchedMotifs.status === 200) {
        return fetchedMotifs.data;
      }
      throw new Error(fetchedMotifs.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  status: "idle",
  motifData: [],
  singleMotif: null,
  meta: null,
};

const motifSlice = createSlice({
  name: 'motifSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //  reducers
    builder
      // get motif request
      .addCase(getAllMotifs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllMotifs.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.motifData = action.payload;
      })
      .addCase(getAllMotifs.rejected, (state, action) => {
        state.status = "failed";
      })
      // one motif request
      .addCase(getSingleMotif.pending, (state, action) => {
      })
      .addCase(getSingleMotif.fulfilled, (state, action) => {
        state.singleMotif = action.payload;
      })
      .addCase(getSingleMotif.rejected, (state, action) => { })
      // create motif request
      .addCase(createMotif.pending, (state, action) => {
      })
      .addCase(createMotif.fulfilled, (state, action) => {
      })
      .addCase(createMotif.rejected, (state, action) => {
      })
      // edit motif request
      .addCase(editMotif.pending, (state, action) => { })
      .addCase(editMotif.fulfilled, (state, action) => { })
      .addCase(editMotif.rejected, (state, action) => { })
      // remove motif request
      .addCase(deleteMotif.pending, (state, action) => { })
      .addCase(deleteMotif.fulfilled, (state, action) => {
      })
      .addCase(deleteMotif.rejected, (state, action) => { });
  },
});

export default motifSlice.reducer;
