import { Slide } from '@material-ui/core';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../notifier/notifierSlice';

import axios from './../../../utils/axios';

const url = '/types';

// fetch all types from server
export const getAllTypes = createAsyncThunk(
  'type/getAll',
  async ({ page, options, rowsPerPage }, thunkAPI) => {
    try {
      const fetchedTypes = await axios.get(url + `?page=${page}${options}&limit=${rowsPerPage}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (fetchedTypes.status === 200) {
        return fetchedTypes.data;
      }
      throw new Error(fetchedTypes.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// show single brand
export const getSingleType = createAsyncThunk(
  'type/getOne',
  async (typeId, thunkAPI) => {
    try {
      const fetchedSingleType = await axios.get(`${url}/${typeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (fetchedSingleType.status === 200) {
        return fetchedSingleType.data.data;
      }
      throw new Error(fetchedSingleType.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// create a new type into options
export const createType = createAsyncThunk(
  'type/create',
  async (data, thunkAPI) => {
    try {
      const createdType = await axios.post(
        url,
      data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (createdType.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              createdType?.data?.message || 'Type is created successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
      }
      setTimeout(() => {
        window.location.href = '/type';
      }, 500);
      return createdType.data.data;
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);
// edit => update type
export const editType = createAsyncThunk(
  'type/edit',
  async (updateType, thunkAPI) => {
    try {
      const updatedType = await axios.patch(
        `${url}/${updateType?.id}`,
        updateType,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (updatedType.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              updatedType?.data?.message || 'Type is updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
      }
      setTimeout(() => {
        window.location.href = '/type';
      }, 500);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

// delete a type
export const deleteType = createAsyncThunk(
  'type/delete',
  async (typeId, thunkAPI) => {
    try {
      const deletedType = await axios.delete(`${url}/${typeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (deletedType.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              deletedType?.data?.message || 'Type is removed successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        return deleteType.data;
      }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);
export const getTotalTypes = createAsyncThunk(
  'type/getAll',
  async ({ page, options, rowsPerPage }, thunkAPI) => {
    try {
      const fetchedTypes = await axios.get(url + `?page=${page}${options}&pagination=false`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (fetchedTypes.status === 200) {
        return fetchedTypes.data;
      }
      throw new Error(fetchedTypes.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  typeData: [],
  typeMeta: null,
  singleType: {
    data: [],
    status: 'idle',
    error: null
  },
};

const typeSlice = createSlice({
  name: 'typeSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //  reducers
    builder
      // get type request
      .addCase(getAllTypes.pending, (state, action) => {
      })
      .addCase(getAllTypes.fulfilled, (state, action) => {
        state.typeData = action.payload;
      })
      .addCase(getAllTypes.rejected, (state, action) => {
      })
      // one status request
      .addCase(getSingleType.pending, (state, action) => {
        state.singleType.status = 'loading';
      })
      .addCase(getSingleType.fulfilled, (state, action) => {
        state.singleType.status = 'succeeded';
        state.singleType.data = action.payload;
      })
      .addCase(getSingleType.rejected, (state, action) => {
        state.singleType.status = 'failed';
      })
      // create type request
      .addCase(createType.pending, (state, action) => {
      })
      .addCase(createType.fulfilled, (state, action) => {
      })
      .addCase(createType.rejected, (state, action) => {
      })
      // edit type request
      .addCase(editType.pending, (state, action) => {})
      .addCase(editType.fulfilled, (state, action) => {})
      .addCase(editType.rejected, (state, action) => {})
      // remove type request
      .addCase(deleteType.pending, (state, action) => {})
      .addCase(deleteType.fulfilled, (state, action) => {
      })
      .addCase(deleteType.rejected, (state, action) => {});
  },
});

export default typeSlice.reducer;
