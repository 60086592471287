//@flow
import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Button, useMediaQuery, Container, Grid } from '@material-ui/core';
import { FilterList, ViewColumn } from '@material-ui/icons';
import SearchInput from '../SearchInput/SearchInput';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { exportBrands } from 'redux/slices/brand/brandSlice';
import { api_export_many } from 'utils/Api';
import { checkPermission } from "../../utils/acl/aclRouter";
import { useStore } from "../../contexts/JWTAuthContext";
import { rolePermissionsList, permissionsList, isAddTrue, isExportTrue } from "../../utils/acl/aclRouter";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  container: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      paddingBottom: '120px',
    },
  },
  smallRow: {
    flexDirection: 'row',
  },
  spacer: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'start',
    },
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  },
  filterButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(-2),
      marginLeft: theme.spacing(-1),
    },
  },
  columnButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  heading: {
    fontWeight: '400',
  },
}));

const Toolbar = (props) => {
  const {
    className,
    toggleColumns,
    toggleFilters,
    searchChange,
    pageLink,
    handleExport,
    searchMessage,
    rowsPerPage,
    title,
    setSearchInput,
    initializeData,
    removeAdd,
    noExport,
    //  data,
    filters,
    search,
    noSearch,
    noColumns,
    ...rest
  } = props;
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch();
  const [isAddPermission, setIsAddPermission] = useState(false);
  const [isExportPermission, setIsExportPermission] = useState(false);

  const name = title
    ? title.toUpperCase()
    : pageLink?.split('/')[1].toUpperCase();

  const classes = useStyles();
  const handleSearch = (e) => {
    searchChange(e.target.value);
  };
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true,
  });

  //const {endpoint} = useSelector(state => state.filteredData)

  let allPrmArr = [];
  allPrmArr = rolePermissionsList(user?.role?.permissions).concat(permissionsList(user?.permissions));
  const route = window.location.pathname;
  const pageName = route.includes('settings') ? route.split('/')[2].toUpperCase() : route.split('/')[1].toUpperCase();

  React.useEffect(() => {
    if (isAddTrue(allPrmArr, pageName, user)) {
      setIsAddPermission(true)
    }
    if (isExportTrue(allPrmArr, pageName, user)) {
      setIsExportPermission(true)
    }
  }, [allPrmArr]);
  const filter = Object.keys(filters).map((key) => `${key}=${filters[key]}`).join('&')
  const windowUrl = window.location.href;
  const exportFiles = () => {
    if (windowUrl.includes('users')) {
      dispatch(api_export_many(`users/export${filter ? `?${filter}` : ''}`));
    }
    if (windowUrl.includes('userMaterial')) {
      dispatch(api_export_many(`user-materiels/export${filter ? `?${filter}` : ''}`));
    }
    if (windowUrl.includes('materials')) {
      dispatch(api_export_many(`materiels/export${filter ? `?${filter}` : ''}`));
    }
    if (windowUrl.includes('status')) {
      dispatch(api_export_many(`statuses/export${filter ? `?${filter}` : ''}`));
    }
    if (windowUrl.includes('type')) {
      dispatch(api_export_many(`types/export${filter ? `?${filter}` : ''}`));
    }
    if (windowUrl.includes('brand')) {
      dispatch(api_export_many(`brands/export${filter ? `?${filter}` : ''}`));
    }
    if (windowUrl.includes('department')) {
      dispatch(api_export_many(`departments/export${filter ? `?${filter}` : ''}`));
    }
    if (windowUrl.includes('locations')) {
      dispatch(api_export_many(`locations/export${filter ? `?${filter}` : ''}`));
    }
    if (windowUrl.includes('modeles')) {
      dispatch(api_export_many(`models/export${filter ? `?${filter}` : ''}`));
    }
    if (windowUrl.includes('requests')) {
      dispatch(api_export_many(`requests/export${filter ? `?${filter}}` : ''}`));
    }
    if (windowUrl.includes('motif')) {
      dispatch(api_export_many(`motifs/export${filter ? `?${filter}}` : ''}`));
    }
    if (windowUrl.includes('rejectMotif')) {
      dispatch(api_export_many(`rejectMotifs/export${filter ? `?${filter}}` : ''}`));
    }
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Typography
          variant={isDesktop ? 'h4' : 'h2'}
          component='h2'
          className={classes.heading}
        >
          {name !== 'USERMATERIAL' ? name : 'USER-MATERIAL'}
        </Typography>
      </div>
      <Grid Container className={classes.container}>
        {!noSearch &&
          <Grid item xs={12}>
            <SearchInput
              className={classes.searchInput}
              placeholder={searchMessage}
              //  setSearchInput={setSearchInput}
              //  data={data}
              onChange={handleSearch}
            />
          </Grid>}
        <Grid item xs={12}>
          <Button
            color='default'
            size='large'
            className={classes.filterButton}
            onClick={toggleFilters}
            startIcon={<FilterList />}
          >
            Filter
          </Button>
          {!noColumns &&
            <Button
              color='default'
              size='large'
              className={classes.columnButton}
              onClick={toggleColumns}
              startIcon={<ViewColumn />}
            >
              Columns
            </Button>}
        </Grid>
        <Grid item xs={12}>
          <div className={classes.spacer}>
            {!noExport && isExportPermission &&
              <Button className={classes.exportButton} onClick={exportFiles}>
                Export
              </Button>}
            {!removeAdd && isAddPermission &&
              <Button
                color='primary'
                variant='contained'
                component={Link}
                to={`${pageLink}/create`}
              >
                Add
              </Button>}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  toggleFilters: PropTypes.func,
  toggleColumns: PropTypes.func,
  searchChange: PropTypes.func,
};

export default Toolbar;
