import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../notifier/notifierSlice';
import { Slide } from '@material-ui/core';
import axios from './../../../utils/axios';
import { api_post_serialize, api_post } from "utils/Api";


const loginURL = '/users/login';

// inventory users url
const usersUrl = '/users';
const updatePasswordUrl = '/users/updateMyPassword';

// login admin
export const loginAdmin = createAsyncThunk(
  'adminUser/login',
  async (adminData, thunkAPI) => {
    let alertMessage;
    try {
      const loggedInResponse = await axios.post(loginURL, adminData);
      alertMessage = 'alert';
      if (loggedInResponse.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: loggedInResponse?.data?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        return loggedInResponse.data;
      }
      throw new Error(loggedInResponse.status);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

// get all inventory users
export const getAllInventoryUsers = createAsyncThunk(
  'users/getAll',
  async ({ page, options, rowsPerPage }, thunkAPI) => {
    try {
      const accessToken = thunkAPI.getState().auth.token;
      const fetchedUsers = await axios.get(
        usersUrl + `?page=${page}${options}&limit=${rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (fetchedUsers.status === 200) {
        return fetchedUsers.data;
      }
      throw new Error(fetchedUsers.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get an inventory user
export const getUser = createAsyncThunk('user/getOne', async (_, thunkAPI) => {
  try {

    const token = thunkAPI.getState().auth.token;
    const id = thunkAPI.getState().auth.id;
    const fetchedUser = await axios.get(`${usersUrl}/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (fetchedUser.status === 200) {
      return fetchedUser.data;
    }
    throw new Error(fetchedUser.status);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.rejectWithValue(message);
    console.log(error.message);
    return thunkAPI.rejectWithValue(message);
  }
});

// show an inventory user
export const showUser = createAsyncThunk('user/show', async (id, thunkAPI) => {
  try {
    const requestedUser = await axios.get(`${usersUrl}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (requestedUser.status === 200) {
      // console.log(requestedUser.data);
      return requestedUser.data;
    }
    throw new Error(requestedUser.status);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.rejectWithValue(message);
    console.log(error.message);
    return thunkAPI.rejectWithValue(message);
  }
});

// create inventory users
export const createUser = createAsyncThunk(
  'user/create',
  async (userData, thunkAPI) => {
    try {
      if (userData.role.label === 'USER') {
        delete userData.password;
      }
      const createdUser = await axios.post(usersUrl, userData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (createdUser.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: createdUser?.data?.message || 'User successfully created',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.pathname = '/users';
        }, 500);
        return createdUser;
      }
      throw new Error(createdUser.status);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

// edit user
export const editUser = createAsyncThunk(
  'user/edit',
  async (userEditData, thunkAPI) => {
    try {
      if (userEditData.role === 'user') {
        delete userEditData.password;
      }
      const editedUserRequest = await axios.patch(
        `${usersUrl}/${userEditData.id}`,
        userEditData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (editedUserRequest.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              editedUserRequest?.data?.message || 'User successfully updated',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.pathname = '/users';
        }, 500);
        return editedUserRequest;
      }
      throw new Error(editedUserRequest.status);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);
// edit user
// edit password
export const updatePassword = createAsyncThunk(
  "user/update",
  async (userPasswordData, thunkAPI) => {
    try {
      const updatedPasswordRequest = await axios.patch(
        updatePasswordUrl,
        userPasswordData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (updatedPasswordRequest.status === 200) {
        localStorage.setItem(
          "token",
          updatedPasswordRequest?.data?.data?.token
        );
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: updatedPasswordRequest?.data?.message || 'Password successfully updated',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.pathname = '/account';
        }, 500);
        return;
      }
      throw new Error(updatedPasswordRequest.status);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : "Something went wrong");
    }
  }
);

// remove user
export const deleteUser = createAsyncThunk(
  'user/delete',
  async (userId, thunkAPI) => {
    try {
      const removedUser = await axios.delete(`${usersUrl}/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (removedUser.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              removedUser?.data?.message || 'user is removed successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        return removedUser.data.data;
      }
      throw new Error(removedUser.status);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);
export const sendUserInvitation = createAsyncThunk(
  'user/sendInvitation',
  async (userId, thunkAPI) => {
    try {
      const userInvitation = await axios.get(`${usersUrl}/sendInvitation/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (userInvitation.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              userInvitation?.data?.message || 'Invitation was sent to user successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        return userInvitation.data.data;
      }
      throw new Error(userInvitation.status);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

export const importFileUsers = createAsyncThunk(
  'user/importFIleUsers',
  async (query, thunkAPI) => {
    const { requestValues } = query;
    let data
    try {
      const importedUser = await api_post(`users/import`, requestValues, true);
      data = await importedUser.data;
      if (importedUser.status === "success") {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              importedUser?.data?.message || 'Users data imported successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.pathname = '/users';
        }, 500);
        return importedUser.data.data;
      }
      throw new Error(importedUser.message);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err?.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log('error', err?.message);
      return Promise.reject(err.message ? err.message : err?.message);
    }
  }
);
// save user login in case still logged in
const token = localStorage.getItem('token');
const userName = localStorage.getItem('userName');
const id = localStorage.getItem('id');

const initialUserState = {
  inventoryUsers: {
    data: [],
    status: 'idle',
    error: null
  },
  sendUserInvitation: {
    status: 'idle',
    error: null
  },
  token: token || null,
  userName: userName || '',
  id: id || null,
  user: {
    data: [],
    status: 'idle',
    error: null,
  },
  alertMessage: '',
  meta: null,
  singleUser: {
    data: [],
    status: 'idle',
    error: null,
  },
  importedData: {
    data: [],
    status: 'idle',
    error: null,
  },
};

const adminSlice = createSlice({
  initialState: initialUserState,
  name: 'userSlice',
  reducers: {
    logoutHandler: (state) => {
      state.token = null;
      state.userName = '';
      localStorage.clear();
      window.location.reload();
      window.location.pathname = '/login';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAdmin.pending, (state, action) => {
      })
      .addCase(loginAdmin.fulfilled, (state, action) => {
        if (typeof action.payload === 'string') {
          state.alertMessage = 'Please verify your information!';
        } else {
          // console.log(action.payload);
          state.id = action.payload.data.user?._id;
          localStorage.setItem('id', action.payload.data.user?._id);
          state.token = action.payload.data.token;
          localStorage.setItem('token', action.payload.data.token);
          state.userName = action.payload.data.user.username;
          localStorage.setItem('userName', action.payload.data.user.username);
          localStorage.setItem(
            "role",
            action.payload.data.user.role.label
          );
          if (action.payload.data.user.role.label && action.payload.data.user.role.label === "USER") {
            setTimeout(() => {
              window.location.pathname = '/account';
            }, 400);
          } else {
            setTimeout(() => {
              window.location.pathname = '/';
            }, 400);
          }
        }
      })
      .addCase(loginAdmin.rejected, (state, action) => { })
      // get user
      .addCase(getUser.pending, (state, action) => {
        state.user.status = 'loading';

      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.user.status = 'succeeded';
        state.user.data = action.payload.data;
        state.user.username = action?.payload?.data?.username;
        state.user.email = action.payload?.data?.email;
        state.user.phoneNumber = action.payload?.data?.phoneNumber;
        state.user.department = action?.payload?.data?.department_id;
        state.user.id = action?.payload?.data?._id;
        state.user.role = action?.payload?.data?.role;
        state.user.permissions = action?.payload?.data?.permissions;


      })
      .addCase(getUser.rejected, (state, action) => {
        state.user.status = 'failed';
        state.user.error = action.payload;
      })
      // show user
      .addCase(showUser.pending, (state, action) => {
        state.singleUser.status = 'loading';
      })
      .addCase(showUser.fulfilled, (state, action) => {
        state.singleUser.status = 'succeeded';
        state.singleUser.data = action.payload?.data;
      })
      .addCase(showUser.rejected, (state, action) => {
        state.singleUser.status = 'failed';
      })
      // edit user
      .addCase(editUser.pending, (state, action) => { })
      .addCase(editUser.fulfilled, (state, action) => {
      })
      .addCase(editUser.rejected, (state, action) => { })

      // get inventory users
      .addCase(getAllInventoryUsers.pending, (state, action) => {
        state.inventoryUsers.status = 'loading';
      })
      .addCase(getAllInventoryUsers.fulfilled, (state, action) => {
        state.inventoryUsers.status = 'succeeded';
        state.inventoryUsers.data = action.payload;

      })
      .addCase(getAllInventoryUsers.rejected, (state, action) => {
        state.inventoryUsers.status = 'failed';
      })
      // create inventory users
      .addCase(createUser.pending, (state, action) => {
      })
      .addCase(createUser.fulfilled, (state, action) => {
      })
      .addCase(createUser.rejected, (state, action) => { })
      .addCase(deleteUser.pending, (state, action) => {
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
      })
      .addCase(deleteUser.rejected, (state, action) => { })
      .addCase(sendUserInvitation.pending, (state, action) => {
        state.sendUserInvitation.status = 'loading'
      })
      .addCase(sendUserInvitation.fulfilled, (state, action) => {
        state.sendUserInvitation.status = 'succeeded'
      })
      .addCase(sendUserInvitation.rejected, (state, action) => {
        state.sendUserInvitation.status = 'failed'
      })
      .addCase(importFileUsers.pending, (state, action) => {
        state.importedData.status = "loading"
      })
      .addCase(importFileUsers.fulfilled, (state, action) => {
        state.importedData.status = "succeeded"
        state.importedData.data = action.payload.data;
      })
      .addCase(importFileUsers.rejected, (state, action) => {
        state.importedData.status = "failed"
      })
      .addCase(updatePassword.pending, (state, action) => { })
      .addCase(updatePassword.fulfilled, (state, action) => {
      })
  },
})


export const { logoutHandler } = adminSlice.actions;

export default adminSlice.reducer;
