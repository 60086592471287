import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_export_many } from "utils/Api";
import { enqueueSnackbar } from "../notifier/notifierSlice";
import { Slide } from "@material-ui/core";


export const actionExportMany = createAsyncThunk(
    'action/exportMany',
    async (query, thunkAPI) => {
        const { deleteUrl, selectedItems } = query;
        let data;
        try {
            const response = api_export_many(`${deleteUrl}/export`, selectedItems, false);
            data = await response;
            if (response.status = "success") {
                thunkAPI.dispatch(
                    enqueueSnackbar({
                        message: data.message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success',
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            },
                            TransitionComponent: Slide,
                        },
                    })
                );
                return data;
            }
            throw new Error(response.statusText);
        } catch (err) {
            thunkAPI.dispatch(
                enqueueSnackbar({
                    message: data.message,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                        TransitionComponent: Slide,
                    },
                })
            );
            console.log(err);
            return Promise.reject(err.message ? err.message : data?.message);
        }
    }
);

const initialState = {
    exportMany: {
        data: [],
        status: "idle",
        error: "null",
    }
};

const exportSlice = createSlice({
    name: 'exportSlice',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [actionExportMany.pending]: (state, action) => {
            state.exportMany.status = "loading";
        },
        [actionExportMany.fulfilled]: (state, action) => {
            state.exportMany.status = "succeeded";
            state.exportMany.data = action.payload;
        },
        [actionExportMany.rejected]: (state, action) => {
            state.exportMany.status = "failed";
            state.exportMany.error = action.payload;
        },
    }

});

export default exportSlice.reducer;
