import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import { AccountProfile, AccountDetails } from './components';
import { getUser, logoutHandler } from 'redux/slices/auth/userSlice';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));
const Account = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  // useEffect(() => {
  //   dispatch(getUser());
  // }, []);

  const { user } = useSelector((state) => state.auth);
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={4} md={6} xl={4} xs={12}>
          <AccountProfile
            username={user.username}
            email={user.email}
            role={user.role?.label}
            phoneNumber={user?.phoneNumber}
            departmentName={user?.department}
          />
        </Grid>
        <Grid item lg={8} md={6} xl={8} xs={12}>
          <AccountDetails
            username={user?.username}
            useremail={user?.email}   
            departmentName={user?.department}  
            id={user?.id}       
          />
        </Grid>
        
      </Grid>
      <div>
      <Button
          style={{
            minWidth: '32%',
            marginTop: '1rem',
            backgroundColor: '#0093E2',
          }}
        >
          <Link
            style={{ width: '100%', color: 'white' }}
            to='/settings/update-password'
          >
            Change your password
          </Link>
        </Button>
      </div>
    </div>
  );
};

export default Account;
