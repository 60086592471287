import { checkPermissionPage, formatTilePage, isSupAdmin, isUser, isAdmin } from "./aclPages";
export const aclPages = (pages, roles, groups) => {
  if (isSupAdmin(roles) || isAdmin(roles)) {
    return pages;
  }

  let result = [];
  console.log(pages)
  pages.forEach(function (page) {
    let temp = false;

    if (page) {
      temp = checkPermissionPage(formatTilePage(page.title), roles);

      if (temp) {
        result.push(page);
      }
    }
  });
  return result;
};
export const rolePermissionsList = (arr) => {
  let newList = [];
  arr && arr.length !== 0 && arr.map((permission) => {
    newList.push(permission?.label);
  })
  return newList;
}
export const checkPermission = (roleName, roles) => {
  let exist = false;
  if (roleName === "*") {
    return true;
  }
  if
    (roleName.includes("/userMaterial/view") &&
    isUser(roles)
  ) {
    return true;
  } else {
    return false
  }


};
export const permissionsList = (arr) => {
  let newList = [];
  arr && arr.length !== 0 && arr.map((permission) => {
    newList.push(permission?.label);
  })
  return newList;
}

export const isAddTrue = (allPrmArr, pageName, user) => {
  if (allPrmArr && allPrmArr.includes(pageName.toUpperCase() + '_CREATE') || user?.role?.label === 'SUPER_ADMIN' || user?.role?.label === 'ADMIN') {
    return true;
  }
}
export const isExportTrue = (allPrmArr, pageName, user) => {
  if (allPrmArr && allPrmArr.includes(pageName.toUpperCase() + '_EXPORT') || user?.role?.label === 'SUPER_ADMIN' || user?.role?.label === 'ADMIN') {
    return true;
  }
}

export const isEditTrue = (allPrmArr, pageName, user) => {
  if (allPrmArr && allPrmArr.includes(pageName.toUpperCase() + '_EDIT') || user?.role?.label === 'SUPER_ADMIN' || user?.role?.label === 'ADMIN') {
    return true;
  }
}
export const isShowTrue = (allPrmArr, pageName, user) => {
  if (allPrmArr && allPrmArr.includes(pageName.toUpperCase() + '_VIEW') || user?.role?.label === 'SUPER_ADMIN' || user?.role?.label === 'ADMIN') {
    return true;
  }
}

export const isDeleteTrue = (allPrmArr, pageName, user) => {
  if (allPrmArr && allPrmArr.includes(pageName.toUpperCase() + '_DELETE') || user?.role?.label === 'SUPER_ADMIN' || user?.role?.label === 'ADMIN') {
    return true;
  }
}
export const isInviteTrue = (allPrmArr, pageName, user) => {
  if (allPrmArr && allPrmArr.includes(pageName.toUpperCase() + '_INVIT') || user?.role?.label === 'SUPER_ADMIN' || user?.role?.label === 'ADMIN') {
    return true;
  }
}
