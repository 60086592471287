import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../notifier/notifierSlice';
import Slide from '@material-ui/core/Slide';
import axios from '../../../utils/axios';

const url = '/locations';

// fetch all locations from server
export const getAllLocations = createAsyncThunk(
  'location/getAll',
  async ({ page, options, rowsPerPage }, thunkAPI) => {
    try {
      const fetchedLocations = await axios.get(
        `${url}/?page=${page}${options}&limit=${rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (fetchedLocations.status === 200) {
        return fetchedLocations.data;
      }
      throw new Error(fetchedLocations.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// fetch one location
export const getSingleLocation = createAsyncThunk(
  'location/getOne',
  async (locationId, thunkAPI) => {
    try {
      const fetchedSingleLocation = await axios.get(`${url}/${locationId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (fetchedSingleLocation.status === 200) {
        return fetchedSingleLocation.data;
      }
      throw new Error(fetchedSingleLocation.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getLocHistoryOfMaterials = createAsyncThunk(
  'LocationHistorty/ofMaterials',
  async (locId, thunkAPI) => {
    try {
      const historyLocation = await axios.get(
        `user-materiels?history=true&locationHistory=${locId.id}&user=null`,
        {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (historyLocation.status === 200) {
        return historyLocation.data;
      }
      throw new Error(historyLocation.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// create a new location into options
export const createLocation = createAsyncThunk(
  'location/create',
  async (locationToCreate, thunkAPI) => {
    try {
      const createdLocation = await axios.post(
        url,
        {
          name: locationToCreate.locationName,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (createdLocation.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              createdLocation?.data?.message || 'location is created successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
      }
      setTimeout(() => {
        window.location.href = '/locations';
      }, 500);
      return createdLocation.data.data;
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);
// edit => update location
export const editLocation = createAsyncThunk(
  'location/edit',
  async (updateLocationData, thunkAPI) => {
    try {
      const updatedLocation = await axios.patch(
        `${url}/${updateLocationData?.id}`,
        {
            name: updateLocationData.locationName,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (updatedLocation.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              updatedLocation?.data?.message ||
              'Location is successfully updated',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.href = '/locations';
        }, 500);
        return updatedLocation?.data?.data?.doc;
      }
      throw new Error(updatedLocation.status);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

// delete a location
export const deleteLocation = createAsyncThunk(
  'location/delete',
  async (locationId, thunkAPI) => {
    try {
      const deletedLocation = await axios.delete(`${url}/${locationId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (deletedLocation.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: 'location removed successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        return deletedLocation.data.data;
      }
      throw new Error(deletedLocation.status);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

export const getTotalLocations = createAsyncThunk(
  'location/getAll',
  async ({ page, options, rowsPerPage }, thunkAPI) => {
    try {
      const fetchedLocations = await axios.get(
        `${url}/?page=${page}${options}&pagination=false`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (fetchedLocations.status === 200) {
        return fetchedLocations.data;
      }
      throw new Error(fetchedLocations.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  locationData: [],
  singleLocation: {
    data : [],
    status: 'idle',
    error : null,
  },
  LocationMaterialsHistory: [],
  meta: null,
};

const locationSlice = createSlice({
  name: 'locationSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //  reducers
    builder
      // get locations request
      .addCase(getAllLocations.pending, (state, action) => {
        console.log('pending the locations');
      })
      .addCase(getAllLocations.fulfilled, (state, action) => {
        state.locationData = action.payload;
      })
      .addCase(getAllLocations.rejected, (state, action) => {
      })
      // get a location request
      .addCase(getSingleLocation.pending, (state, action) => {
        state.singleLocation.status = "loading";
       })
      .addCase(getSingleLocation.fulfilled, (state, action) => {
        state.singleLocation.status = "succeeded";
        state.singleLocation.data = action.payload;
      })
      .addCase(getSingleLocation.rejected, (state, action) => { 
        state.singleLocation.status = "failed";
      })
      //history of materials
      .addCase(getLocHistoryOfMaterials.pending, (state, action) => {})
      .addCase(getLocHistoryOfMaterials.fulfilled, (state, action) => {
        state.LocationMaterialsHistory = action.payload?.data;
      })
      .addCase(getLocHistoryOfMaterials.rejected, (state, action) => {})
      // create location request
      .addCase(createLocation.pending, (state, action) => {
      })
      .addCase(createLocation.fulfilled, (state, action) => {
      })
      .addCase(createLocation.rejected, (state, action) => {
      })
      // update location request
      .addCase(editLocation.pending, (state, action) => { })
      .addCase(editLocation.fulfilled, (state, action) => { })
      .addCase(editLocation.rejected, (state, action) => { })
      // delete location request
      .addCase(deleteLocation.pending, (state, action) => { })
      .addCase(deleteLocation.fulfilled, (state, action) => {
      })
      .addCase(deleteLocation.rejected, (state, action) => { });
  },
});

export default locationSlice.reducer;
