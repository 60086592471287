import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginAdmin } from 'redux/slices/auth/userSlice';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
  Slide,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'redux/slices/notifier/notifierSlice';
import { Redirect, useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {},
}));

const JWTLogin = ({ className, ...rest }) => {



  const classes = useStyles();
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();

  const dispatch = useDispatch();
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');

  const onEmailChange = (e) => {
    setEmailInput(e.target.value);
  };
  const onPasswordChange = (e) => {
    setPasswordInput(e.target.value);
  };

  const formValidation = (email, password) => {
    if (!email) {
      return { success: false, message: 'email is missing' };
    }
    if (!password) {
      return { success: false, message: 'password is missing' };
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
        const {keyCode} = e;
        if( keyCode === 13) {
          e.preventDefault();
          loginSubmit(e);
        }
    }
      window.addEventListener('keydown',handleKeyPress);
    return () => {
      window.removeEventListener('keydown',handleKeyPress);
    }
  },);
  const { user } = useSelector((state) => state.auth);
  const history=useHistory()
  const loginSubmit = (e) => {
    const validation = formValidation(emailInput, passwordInput);
    const role=localStorage.getItem('role')
    e.preventDefault();
    if (validation) {
      if  (( user?.data?.role?.label === "USER" &&
      user?.data?.role?.label !== undefined)||  role=== "USER") {
       console.log("enter");
       return <Redirect to='/account'/>
      }
      dispatch(
        enqueueSnackbar({
          message: validation.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );

     
    }
    const loginInputData = { email: emailInput, password: passwordInput };
    if (!validation) {
      dispatch(loginAdmin(loginInputData));
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .max(255)
          .required('Username is required'),
        password: Yup.string()
          .max(255)
          .required('Password is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await login(values.email, values.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          onSubmit={loginSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <TextField
            value={emailInput}
            id='email'
            className='input'
            onChange={onEmailChange}
            fullWidth
            autoFocus
            label='Email'
            margin='normal'
            name='email'
            type='email'
            variant='outlined'
          />
          <TextField
            value={passwordInput}
            className='input'
            id='password'
            onChange={onPasswordChange}
            fullWidth
            label='Password'
            margin='normal'
            name='password'
            type='password'
            variant='outlined'
          />
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color='primary'
              disabled={isSubmitting}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
            >
              Log In
            </Button>
          </Box>
          {/* <Box mt={2}>
            <Alert severity='info'>
              <div>
                Use <b>admin</b> and password <b>admin</b>
              </div>
            </Alert>
          </Box> */}
        </form>
      )}
    </Formik>
  );
};

JWTLogin.propTypes = {
  className: PropTypes.string,
};

export default JWTLogin;
