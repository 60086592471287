// @flow
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Box,
  CardMedia,
  useMediaQuery,
  Slide,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import { AsyncPaginate } from "react-select-async-paginate";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import dayjsAdapter from "@material-ui/pickers/adapter/dayjs";
import PropTypes from "prop-types";
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import { Link, useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import LoadingApi from "components/LoadingApi/LoadingApi";
import theme from "theme";
import { useDispatch, useSelector } from "react-redux";
import {
  editMaterial,
  getOneMaterial,
} from "redux/slices/materials/materialsSlice";
import { getLocations, getModeles, getTypes, getStatus, getBrand } from "utils/methods";
import { enqueueSnackbar } from "redux/slices/notifier/notifierSlice";
import  Download from '../../../assets/images/Download.svg';
import  deleteIcon from '../../../assets/images/deleteIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
  button: {
    margin: "10px",
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginTop: 100,
    },
  },
}));
type Props = {
  edit: boolean,
  className: string,
};

const initialState = {
  name: "",
  note: null,
  status_id: null,
  brand_id: null,
  type_id: null,
 // available: "1",
  serial_number: null,
  images: [],
  location_id: null,
  model_id: "",
  price: null,
  date_of_purchase: dayjs().format("YYYY-MM-DD"),
};

const FormEdit = (props: Props): React$Element<any> => {
  const { id } = useParams();
  const { className, edit, ...rest } = props;
  const dispatch = useDispatch();
  const [importedCodes, getCodes] = useState([]);
  const [message, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [severity, setAlertSeverity] = useState("success");
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);
  const responsive = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const [brandInput, setBrandInput] = useState('');
  const [typeInput, setTypeInput] = useState('');
  const [locationInput, setLocationInput] = useState('');
  const [modeleInput, setModeleInput] = useState('');
  const [statusInput, setStatusInput] = useState('');
  const [values, setValues] = useState(initialState);
  const [uploadedFilesNames, setUploadedFilesNames] = useState([]);
  const [resetShow, setResetShow] = useState(false);
  const { singleMaterial } = useSelector((state) => state.materials);

  useEffect(() => {
    const handleKeyPress = (e) => {
        const {keyCode} = e;
        if( keyCode === 13) {
          e.preventDefault();
          handleSubmit(e);
        }
    }
      window.addEventListener('keydown',handleKeyPress);
    return () => {
      window.removeEventListener('keydown',handleKeyPress);
    }
  },);

  useEffect(() => {
    if (edit) {
      dispatch(getOneMaterial(id));
    }
  }, []);


  const serialNumberGeneration = () => {
    dispatch(
      enqueueSnackbar({
        message:
          "If the Material no longer has a Serial Number, keep the field empty to auto-generate",
        options: {
          key: new Date().getTime() + Math.floor(Math.random() * 150),
          variant: "info",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          TransitionComponent: Slide,
        },
      })
    );
  };

  useEffect(() => {
    if (singleMaterial.status === "succeeded") {
      setValues({
        name: singleMaterial?.data.name,
        note: singleMaterial?.data.note,
        status_id: singleMaterial?.data.status_id,
        brand_id: singleMaterial?.data.brand_id,
        type_id: singleMaterial?.data.type_id,
        available: singleMaterial?.data.available,
        serial_number: singleMaterial?.data.serial_number,
        images: singleMaterial?.data.images,
        model_id: singleMaterial?.data?.model_id,
        location_id: singleMaterial?.data?.location_id,
        price: singleMaterial?.data?.price,
        date_of_purchase: singleMaterial?.data?.date_of_purchase,
      });
    }
    if (!edit) {
      setValues(initialState);
    }
  }, [singleMaterial.status, edit]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
  };

  const formValidation = (values) => {
    if (!values?.name) {
      return { success: false, message: "Name  is missing" };
    }
    //   if (!values?.note) {
    //     return { success: false, message: "Note  is missing" };
    //   }
    // if (!values?.serial_number) {
    //   return { success: false, message: "Serial Number is missing" };
    // }
    if (!values?.status_id) {
      return { success: false, message: "Status is missing" };
    }
    if (!values?.brand_id) {
      return { success: false, message: "Brand is missing" };
    }
    if (!values?.type_id) {
      return { success: false, message: "Type  is missing" };
    }
    if (!values?.model_id) {
      return { success: false, message: "Modele is missing" };
    }
    if (!values?.location_id) {
      return { success: false, message: "Location is missing" };
    }
    // if (!values?.price) {
    //   return { success: false, message: "Price is missing" };
    // }
  };
  const handleSubmit = () => {
    const validations = formValidation(values);
    if (validations) {
      dispatch(
        enqueueSnackbar({
          message: validations?.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    } else {
      const requestValues = mapStateToRequest(values, ["images"]);
      requestValues.id = id;
      requestValues.available = values.available;
      if (requestValues?.serial_number === "") {
        requestValues.serial_number = null;
      }
      if (requestValues?.note === "") {
        requestValues.note = null;
      }
      dispatch(editMaterial(requestValues));
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleLocationChange = (options) => {
    setValues({
      ...values,
      location_id: options,
    });
  };

  const handleModeleChange = (options) => {
    setValues({
      ...values,
      model_id: options,
    });
  };

  const handleBrandChange = (options) => {
    setValues({
      ...values,
      brand_id: options,
    });
  };

  const handleStatusChange = (options) => {
    setValues({
      ...values,
      status_id: options,
    });
  };

  const handleTypeChange = (options) => {
    setValues({
      ...values,
      type_id: options,
    });
  };
  const datePurshaseChange = (value) => {
    if (value) {
      setValues({ ...values, date_of_purchase: value.format("YYYY-MM-DD hh:mm") });
    }
  };
  let namesArr = [];
  const onFileChange = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    setValues({ ...values, images: chosenFiles });

    for(let i=0 ; i< chosenFiles.length ; i++){
      namesArr.push(e.target.files[i].name);
      setUploadedFilesNames(namesArr);
    }
    if(chosenFiles.length !==0){
    setResetShow(true);
    }
  };

  const handleReset = () => {
    setValues({ ...values, images: [] });
    setUploadedFilesNames([]);
    setResetShow(false);
  };

  const hiddenFileInput = React.useRef(null);
  const hiddenResetInput = React.useRef(null);

  const handleClickReset = () => {
    hiddenResetInput.current.click();
  };

  const handleClickUpload = () => {
    hiddenFileInput.current.click();
  };
  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <CardHeader subheader="Materials form" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <p></p>
              <Grid item md={2} xs={4}>
                <TextField
                  fullWidth
                  name="name"
                  id="name"
                  variant="outlined"
                  label="Name"
                  value={values?.name || ""}
                  onChange={handleChange}
                ></TextField>
              </Grid>
              <Grid item md={2} xs={4}>
                <TextField
                  fullWidth
                  name="note"
                  variant="outlined"
                  label="note"
                  value={values?.note || ""}
                  onChange={handleChange}
                ></TextField>
              </Grid>
              <Grid item md={2} xs={4}>
                <TextField
                  onFocus={serialNumberGeneration}
                  fullWidth
                  name="serial_number"
                  variant="outlined"
                  label="Serial Number"
                  value={values?.serial_number || ""}
                  onChange={handleChange}
                ></TextField>
              </Grid>
              <Grid item md={2} xs={4}>
                <TextField
                  fullWidth
                  name="price"
                  id="price"
                  variant="outlined"
                  type="number"
                  label="Price"
                  value={values?.price}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                ></TextField>
              </Grid>
              <Grid item md={2} xs={4}>
                <LocalizationProvider dateAdapter={dayjsAdapter}>
                  <DatePicker
                    label="Date of Purshase"
                    name="date_of_purchase"
                    inputVariant="outlined"
                    inputFormat="DD/MM/YYYY"
                    value = {values.date_of_purchase}
                    onChange={datePurshaseChange}
                    renderInput={(props) => (
                      <TextField {...props} fullWidth variant="outlined" />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={2} xs={4}>
                    <AsyncPaginate
                          required
                          name={"status_id"}
                          loadOptions={getStatus}
                          value={ values.status_id}
                          onChange={handleStatusChange}
                          getOptionLabel={(option) =>
                            option?.name
                          }
                          getOptionValue={(option) => option._id}
                          inputValue={statusInput}
                          onInputChange={setStatusInput}
                          placeholder='Status *'
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                        />
                    </Grid>
                    <Grid item md={2} xs={4}>
                      <AsyncPaginate
                          required
                          name={"brand_id"}
                          loadOptions={getBrand}
                          value={ values.brand_id}
                          onChange={handleBrandChange}
                          getOptionLabel={(option) =>
                            option?.name
                          }
                          getOptionValue={(option) => option._id}
                          inputValue={brandInput}
                          onInputChange={setBrandInput}
                          placeholder='Brand *'
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                        />
                    </Grid>
                    <Grid item md={2} xs={4}>
                    <AsyncPaginate
                          required
                          name={"type_id"}
                          loadOptions={getTypes}
                          value={ values.type_id}
                          onChange={handleTypeChange}
                          getOptionLabel={(option) =>
                            option?.name
                          }
                          getOptionValue={(option) => option._id}
                          inputValue={typeInput}
                          onInputChange={setTypeInput}
                          placeholder='Type *'
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                        />
                    </Grid>
                    <Grid item md={2} xs={12}>
                    <AsyncPaginate
                          required
                          name={"model_id"}
                          loadOptions={getModeles}
                          value={ values.model_id}
                          onChange={handleModeleChange}
                          getOptionLabel={(option) =>
                            option?.name
                          }
                          getOptionValue={(option) => option._id}
                          inputValue={modeleInput}
                          onInputChange={setModeleInput}
                          placeholder='Modele *'
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                        />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <AsyncPaginate
                          required
                          name={"location_id"}
                          loadOptions={getLocations}
                          value={ values.location_id}
                          onChange={handleLocationChange}
                          getOptionLabel={(option) =>
                            option?.name
                          }
                          getOptionValue={(option) => option._id}
                          inputValue={locationInput}
                          onInputChange={setLocationInput}
                          placeholder='Locations *'
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                        />
                    </Grid>
              {/* <Grid style={{ position: "relative" }} item md={2} xs={4}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Available</FormLabel>
                  <RadioGroup
                    aria-label="Available"
                    name="available"
                    onChange={handleChange}
                    value={values?.available || ""}
                    row
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value="1"
                      label="In Stock"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value="0"
                      label="In Use"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid> */}
              <Grid
                item
                md={2}
                xs={2}>
                         <input 
                            type='file' 
                            style={{display:'none'}} 
                            ref={hiddenFileInput} 
                            accept=".jpeg, .png, .jpg" 
                            multiple 
                            onChange={onFileChange} />
                            <Tooltip
                               enterDelay={300}
                               title="Upload image(s)">
                          <img 
                            style={{cursor:'pointer'}} 
                            alt="upload img" src={Download} 
                            onClick={handleClickUpload}/>
                            
                            </Tooltip>
                            <input 
                            type="reset" 
                            style={{display:'none'}} 
                            ref={hiddenResetInput} 
                            onClick={handleClickReset}/>
                          {resetShow &&
                          <img 
                             style={{ marginLeft:'2px', cursor:'pointer'}} 
                             alt="delete img" 
                             src={deleteIcon} 
                             onClick={handleReset}/>}
                          {uploadedFilesNames.length !==0 &&
                               uploadedFilesNames.map((item)=>
                              <li>{item}</li>)}
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Save details
            </Button>
          </CardActions>
        </form>
      </Card>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
      <LoadingApi
        open={singleMaterial.status === "loading"}
        onClick={handleCloseLoading}
      />
    </div>
  );
};

FormEdit.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
  loading: PropTypes.bool,
};

export default FormEdit;
