import React from 'react';
import { Redirect } from 'react-router-dom';
import { useStore } from '../contexts/JWTAuthContext';

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useStore();

  if (isAuthenticated) {
    return <Redirect to='/' />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {};

export default GuestGuard;
