import { Slide } from '@material-ui/core';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../notifier/notifierSlice';

import axios from './../../../utils/axios';

// urls:
const url = '/rejectMotifs';

// fetch all rejectMotifs from server
export const getAllrejectMotifs = createAsyncThunk(
  'rejectMotifs/getAll',
  async ({ page, options, rowsPerPage }, thunkAPI) => {
    try {
      const fetchedrejectMotifs = await axios.get(url + `?page=${page}${options}&limit=${rowsPerPage}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (fetchedrejectMotifs.status === 200) {
        return fetchedrejectMotifs.data;
      }
      throw new Error(fetchedrejectMotifs.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);


// show single rejectMotif
export const getSinglerejectMotif = createAsyncThunk(
  'rejectMotif/getOne',
  async (rejectMotifId, thunkAPI) => {
    try {
      const fetchedSinglerejectMotif = await axios.get(`${url}/${rejectMotifId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (fetchedSinglerejectMotif.status === 200) {
        return fetchedSinglerejectMotif.data.data;
      }
      throw new Error(fetchedSinglerejectMotif.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// create a new rejectMotif into options
export const createrejectMotif = createAsyncThunk(
  'rejectMotif/create',
  async (rejectMotifToCreate, thunkAPI) => {
    try {
      const createdrejectMotif = await axios.post(
        url,
        {
          name: rejectMotifToCreate,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (createdrejectMotif.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              createdrejectMotif?.data?.message || 'Reject rejectMotif is created successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.pathname = '/rejectMotif';
        }, 500);
        return createdrejectMotif.data.data;
      }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);
// edit => update rejectMotif
export const editrejectMotif = createAsyncThunk(
  'rejectMotif/edit',
  async (updaterejectMotif, thunkAPI) => {
    try {
      const updatedrejectMotif = await axios.patch(
        `${url}/${updaterejectMotif?.id}`,
        {
          name: updaterejectMotif?.rejectMotif,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (updatedrejectMotif.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              updatedrejectMotif?.data?.message || 'Reject rejectMotif is updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
      }
      setTimeout(() => {
        window.location.pathname = '/rejectMotif';
      }, 500);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

// delete a rejectMotif
export const deleterejectMotif = createAsyncThunk(
  'rejectMotif/delete',
  async (rejectMotifId, thunkAPI) => {
    try {
      const deletedrejectMotif = await axios.delete(`${url}/${rejectMotifId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (deletedrejectMotif.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              deletedrejectMotif?.data?.message || 'Reject Motif is removed successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        return deletedrejectMotif.data.data;
      }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

export const getTotalrejectMotifs = createAsyncThunk(
  'rejectMotifs/getAll',
  async ({ page, options, rowsPerPage }, thunkAPI) => {
    try {
      const fetchedrejectMotifs = await axios.get(url + `?page=${page}${options}&pagination=false`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (fetchedrejectMotifs.status === 200) {
        return fetchedrejectMotifs.data;
      }
      throw new Error(fetchedrejectMotifs.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  status: "idle",
  rejectMotifData: [],
  singlerejectMotif: null,
  meta: null,
};

const rejectMotifSlice = createSlice({
  name: 'rejectMotifSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //  reducers
    builder
      // get rejectMotif request
      .addCase(getAllrejectMotifs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllrejectMotifs.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.rejectMotifData = action.payload;
      })
      .addCase(getAllrejectMotifs.rejected, (state, action) => {
        state.status = "failed";
      })
      // one rejectMotif request
      .addCase(getSinglerejectMotif.pending, (state, action) => {
      })
      .addCase(getSinglerejectMotif.fulfilled, (state, action) => {
        state.singlerejectMotif = action.payload;
      })
      .addCase(getSinglerejectMotif.rejected, (state, action) => { })
      // create rejectMotif request
      .addCase(createrejectMotif.pending, (state, action) => {
      })
      .addCase(createrejectMotif.fulfilled, (state, action) => {
      })
      .addCase(createrejectMotif.rejected, (state, action) => {
      })
      // edit rejectMotif request
      .addCase(editrejectMotif.pending, (state, action) => { })
      .addCase(editrejectMotif.fulfilled, (state, action) => { })
      .addCase(editrejectMotif.rejected, (state, action) => { })
      // remove rejectMotif request
      .addCase(deleterejectMotif.pending, (state, action) => { })
      .addCase(deleterejectMotif.fulfilled, (state, action) => {
      })
      .addCase(deleterejectMotif.rejected, (state, action) => { });
  },
});

export default rejectMotifSlice.reducer;
