// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputLabel, MenuItem, Select, FormControl } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { enqueueSnackbar } from 'redux/slices/notifier/notifierSlice';
import { AsyncPaginate } from 'react-select-async-paginate';
import mapStateToRequest from '../../../../helpers/mapStateToRequest';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Slide,
} from '@material-ui/core';
import { getDepartments} from '../../../../utils/methods';
import {
  getAllDepartments,
} from 'redux/slices/department/departmentSlice';
import { editUser } from 'redux/slices/auth/userSlice';
import { id } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    overflow: 'visible',
  },
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },
  content: {
    padding: 0,
  },
  uploadedFiles: {
    marginTop: 10,
  },
}));

type Props = {
  className: string,
};

const AccountDetails = (props: Props): React$Element<any> => {
  useEffect(() => {
    dispatch(getAllDepartments({ page: 1, options: null }));
  }, []);

 const { departmentsData } = useSelector((state) => state.department);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const { className, username, useremail, id, departmentName, ...rest } = props;
  const classes = useStyles();
  const [departmentInput, setDepartmentInput] = useState('');
  const [values, setValues] = useState({
    username: null,
    department_id: null,
    email: null,
  });
  useEffect(() => {
      setValues({
        username: username,
        email: useremail,
        department_id: departmentName,
      });  

  }, [username,useremail,departmentName]);

    const handleNameChange = (event) => {
      setValues({
        ...values,
        username: event.target.value,
      });
    };
    const handleEmailChange = (event) => {
      setValues({
        ...values,
        email: event.target.value,
      });
    };

    const handleDepartmentChange = (options) => {
      setValues({
        ...values,
        department_id: options,
      });
    };

  const formValidation = (username, email, department) => {
    if (!values.username) {
      return { success: false, message: 'Username is missing' };
    }
    if (!/^[ ً ُ ٌ ِ ٍ ّء-يa-zA-Z]{2,20}(\s[ء-ي ً ُ ٌ ِ ٍ ّa-zA-Z](\s+){2,20})?$/.test(values.username)) {
      return { success: false, message: 'Username is invalid' };
    }
    if (!values.email) {
      return { success: false, message: 'Email is missing' };
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)) {
      return { success: false, message: 'Email is invalid' };
    }
  };

  const handleSubmit = (e) => {
    const validation = formValidation(values);
    e.preventDefault();
    if (validation) {
      dispatch(
        enqueueSnackbar({
          message: validation.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
    const formData = values;
    formData.id = id;
    formData.department_id = values.department_id._id;

    if (window.location.href.includes('account') && !validation) {
      dispatch(editUser(formData));
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete='off' noValidate>
        <CardHeader subheader='User information' />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                type='text'
                label='User Name'
                name='username'
                onChange={handleNameChange}
                required
                value={values.username || username}
                variant='outlined'
                disabled= {user?.data?.role?.label === 'USER' ? true : false}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                type='text'
                label='Email Address'
                name='email'
                onChange={handleEmailChange}
                required
                value={values.email || useremail}
                variant='outlined'
                disabled= {user?.data?.role?.label === 'USER' ? true : false}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              {/* <FormControl
                style={{ width: '100%' }}
                variant='outlined'
                sx={{ m: 1, minWidth: 400 }}
              >
                <InputLabel style={{marginTop:'-6px'}} id='demo-simple-select-standard-label'>
                  User Department
                </InputLabel>
                <Select onChange={handleDepartmentChange}
                        value={values.department_id || departmentName}>
                  {departmentsData?.data?.map((department) => {
                    return (
                      <MenuItem value={department?._id}>
                        {department?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl> */}
              <AsyncPaginate
                      loadOptions={getDepartments}
                      value={values.department_id || departmentName}
                      onChange={handleDepartmentChange}
                      getOptionLabel={(option) =>
                        option?.name
                      }
                      getOptionValue={(option) => option._id}
                      placeholder='User Department'
                      inputValue={departmentInput}
                      onInputChange={setDepartmentInput}
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                      isDisabled= {user?.data?.role?.label === 'USER' ? true : false}
                      isClearable= {user?.data?.role?.label === 'USER' ? true : false}

                    />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {user?.data?.role?.label === 'USER' ? undefined : 
        <CardActions>
          <Button color='primary' variant='contained' onClick={handleSubmit}>
            Save details
          </Button> 
        </CardActions>}
      </form>
    </Card>
  );
};

export default AccountDetails;
