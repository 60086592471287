import { Slide } from '@material-ui/core';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../notifier/notifierSlice';

import axios from './../../../utils/axios';

// urls:
const url = '/departments';

// fetch all departments from server
export const getAllDepartments = createAsyncThunk(
  'departments/getAll',
  async ({ page, options, rowsPerPage  }, thunkAPI) => {
    try {
      const fetchedDepartments = await axios.get(
        `${url}?page=${page}${options}&limit=${rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (fetchedDepartments.status === 200) {
        return fetchedDepartments.data;
      }
      throw new Error(fetchedDepartments.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// show one department
export const fetchDepartment = createAsyncThunk(
  'departments/getOne',
  async (id, thunkAPI) => {
    try {
      const singleDepartment = await axios.get(`${url}/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (singleDepartment.status === 200) {
        return singleDepartment.data;
      }
      throw new Error(singleDepartment.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDepartHistoryOfMaterials = createAsyncThunk(
  'departmentsHistorty/ofMaterials',
  async (depId, thunkAPI) => {
    try {
      const historyDepartment = await axios.get(
        `user-materiels?history=true&departmentHistory=${depId}`,
        {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (historyDepartment.status === 200) {
        return historyDepartment.data;
      }
      throw new Error(historyDepartment.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// create a new department
export const createDepartment = createAsyncThunk(
  'department/create',
  async (departmentToCreate, thunkAPI) => {
    try {
      const createdDepartment = await axios.post(
        url,
        { name: departmentToCreate.department },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (createdDepartment.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              createdDepartment?.data?.message ||
              'department is created successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.pathname = '/department';
        }, 500);
        return createdDepartment.data.data;
      }
      throw new Error(createdDepartment.status);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

// edit => update department
export const editDepartment = createAsyncThunk(
  'type/edit',
  async (departmentUpdateData, thunkAPI) => {
    try {
      const token = localStorage.getItem('token');
      const updatedDepartment = await axios.patch(
        `${url}/${departmentUpdateData.id}`,
        { name: departmentUpdateData.department },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (updatedDepartment.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              updatedDepartment?.data?.message ||
              'department is updated successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.pathname = '/department';
        }, 500);
        return;
      }
      throw new Error(updatedDepartment.status);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

// delete a department
export const removeDepartment = createAsyncThunk(
  'department/delete',
  async (departmentId, thunkAPI) => {
    try {
      const token = localStorage.getItem('token');
      const deletedDepartment = await axios.delete(`${url}/${departmentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (deletedDepartment.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              deletedDepartment?.data?.message ||
              'department is removed successfully',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
       return deletedDepartment.data.data;
      }
      throw new Error(deletedDepartment.status);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err.message || 'Something went wrong',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : 'Something went wrong');
    }
  }
);

const initialState = {
  departmentsData: [],
  depratmentsObjectData: {
    data : [],
    status: 'idle',
    error : null,
  },
  departmentMaterialsHistory: [],
  meta: null,
};

const departmentReducer = createSlice({
  name: 'departmentSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    //  reducers
    builder
      // get departments request
      .addCase(getAllDepartments.pending, (state, action) => {
      })
      .addCase(getAllDepartments.fulfilled, (state, action) => {
        state.departmentsData = action.payload;
      })
      .addCase(getAllDepartments.rejected, (state, action) => {
      })
      // get a department async request
      .addCase(fetchDepartment.pending, (state, action) => {
        state.depratmentsObjectData.status = 'loading';

      })
      .addCase(fetchDepartment.fulfilled, (state, action) => {
        state.depratmentsObjectData.status = 'succeeded';
        state.depratmentsObjectData.data = action.payload.data;
      })
      .addCase(fetchDepartment.rejected, (state, action) => {
        state.depratmentsObjectData.status = 'failed';
      })
      //history of materials
      .addCase(getDepartHistoryOfMaterials.pending, (state, action) => {})
      .addCase(getDepartHistoryOfMaterials.fulfilled, (state, action) => {
        state.departmentMaterialsHistory = action.payload?.data;
      })
      .addCase(getDepartHistoryOfMaterials.rejected, (state, action) => {})
      // create department request
      .addCase(createDepartment.pending, (state, action) => {
      })
      .addCase(createDepartment.fulfilled, (state, action) => {
      })
      .addCase(createDepartment.rejected, (state, action) => {})
      // edit department request
      .addCase(editDepartment.pending, (state, action) => {})
      .addCase(editDepartment.fulfilled, (state, action) => {})
      .addCase(editDepartment.rejected, (state, action) => {})
      // remove department request
      .addCase(removeDepartment.pending, (state, action) => {})
      .addCase(removeDepartment.fulfilled, (state, action) => {
      })
      .addCase(removeDepartment.rejected, (state, action) => {});
  },
});

export default departmentReducer.reducer;
