import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import theme from '../../../theme';
import {AsyncPaginate} from 'react-select-async-paginate';
import { Select, MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    // padding: theme.spacing(0.5, 6),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.common.white,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.text.disabled,
    },
    '& .MuiSelect-icon': {
      color: theme.palette.grey[500],
      // right: theme.spacing(1),
      position: 'absolute',
      cursor: 'pointer', 
    },
    '& .MuiSelect-select': {
      padding: theme.spacing(1.2, 6, 1.2, 2),
      width: 100
    }
  },
  menuItem: {
    textTransform: 'capitalize',
  },
}));

const StatusFilter = (props) => {
  const { className, filtersChange, field, values, ...rest } = props;
  const classes = useStyles();

  const statusOptions = [
    { label: "pending", _id: "pending" },
    { label: "accepted", _id: "accepted" },
    { label: "rejected", _id: "rejected" },
  ];  

  const handleChange = (event) => {
    filtersChange({
      [field.name]: event.target.value,
    });
  };

  return (
    // <div {...rest} className={clsx(classes.root, className)}>
      <Select
        onChange={handleChange}
        value={values[field.name] || ''}
        displayEmpty
        className={classes.select}
        inputProps={{ 'aria-label': field.label }}
        MenuProps={{ disableHoverListener: true }}
        label={"Status"}

      >
        <MenuItem value="" disabled>
          {field.label}
        </MenuItem>
        <MenuItem value={undefined}>
            <em>None</em>
          </MenuItem>
        {statusOptions.map((option) => (
          <MenuItem key={option._id} value={option._id} className={classes.menuItem}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    // </div>
  );
};

StatusFilter.propTypes = {
  className: PropTypes.string,
  filtersChange: PropTypes.func,
  field: PropTypes.object,
  values: PropTypes.object,
  handleChange: PropTypes.func,
};

export default StatusFilter;
