import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import RequestIcon from '@material-ui/icons/Mail';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArchiveIcon from '@material-ui/icons/Archive';
import AppsIcon from '@material-ui/icons/Apps';
import BarChartIcon from '@material-ui/icons/BarChart';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import HighlightIcon from '@material-ui/icons/Highlight';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import SettingsIcon from '@material-ui/icons/Settings';
import DnsIcon from '@material-ui/icons/Dns';
import Looks5Icon from '@material-ui/icons/Looks5';
import { Profile, SidebarNav } from './components';
import { api_get } from '../../../../utils/Api';
import Loading from '../../../../components/Loading/Loading';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import HistoryIcon from '@material-ui/icons/History';
import { SettingsApplications } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getUser } from 'redux/slices/auth/userSlice';
import jwtDecode from 'jwt-decode';
const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(9) + 1,
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(9) + 5,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Sidebar = (props) => {
 // const {user, token } = useSelector((state) => state.auth);
  const { open, variant, onClose, className, Click, sidebar,pages,user, ...rest } = props;
  const theme = useTheme();
  const classes = useStyles();
 //console.log(user)

  return (
    <Drawer
      ModalProps={{ onBackdropClick: onClose }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      anchor='left'
      classes={{ paper: classes.drawer }}
      onClose={open}
      open={open}
      variant={variant}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <div className={classes.toolbar}>
          <IconButton onClick={onClose}></IconButton>
        </div>
        <Profile profil={open} />

        <Divider className={classes.divider} />
        {/* {token && ( */}
          <SidebarNav
            Click={Click}
            sidebar={open}
            className={classes.nav}
            pages={pages}
            user={user}
          />
        {/* )} */}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
