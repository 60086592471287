import { createSlice } from '@reduxjs/toolkit';

const initialBrowserState = {
  showSubstance: null,
  deleteMessage: '',
};

const showSlice = createSlice({
  name: 'showSlice',
  initialState: initialBrowserState,
  reducers: {
    getUrl: (state, action) => {
      state.showSubstance = action.payload;
    },
    removeItem: (state, action) => {
      state.deleteMessage = action.payload;
    },
  },
});

export const { getUrl, removeItem } = showSlice.actions;

export default showSlice.reducer;
