import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import MinimalLayout from './layouts/Minimal';
import MainLayout from './layouts/Main';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import LoginView from './views/LoginView';
import { useStore } from './contexts/JWTAuthContext';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import RoleGuard from 'components/RoleGuard';


export const RenderRoutes = ({ routes }) => {
  const { user, isAuthenticated } = useStore();
  // const { user, isAuthenticated } = useStore();
  // const { user } = useSelector((state) => state.auth)

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {
          routes.map((route, i) => {
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;
            const Component = route.component;
            const help =
              route.routes &&
              route?.routes?.filter((item) => {
                return (
                  item.path === "/userMaterial/view/:id" ||
                  item.path === "*" ||
                  item.path === "/" ||
                  item.path === "/requests/reclaim/:id" ||
                  item.path === "/account" ||
                  item.path === "/requests/create" || item.path === "/settings/update-password"
                );
              });
            const role = localStorage.getItem('role')
            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <Guard>
                    <Layout>
                      <Helmet>
                        <title>{route.title}</title>
                      </Helmet>
                      {route.routes ? (
                        <RenderRoutes
                          routes={
                            (user?.data?.role?.label === "USER" &&
                              user?.data?.role?.label !== undefined) || role === "USER"
                              ? help
                              : route.routes
                          }
                        />
                      ) : !user && !isAuthenticated ? (
                        <LoginView />
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </Guard>
                )}
              />
            );
          })}
      </Switch>
    </Suspense>
  );
};
const routes = [
  {
    exact: true,
    layout: MinimalLayout,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("./views/LoginView")),
  },
  {
    path: "/",
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: "/",
        component: lazy(() => import("./views/Home")),
        title: "TA|Home",
      },
      {
        exact: true,
        path: "/account",
        component: lazy(() => import("./views/Account")),
        title: "TA|Account",
      },

      {
        exact: true,
        path: "/settings/update-password",
        component: lazy(() =>
          import("./views/Account/components/AccountProfile/updatePassword")
        ),
        title: "TA|update password",
      },

      {
        exact: true,
        path: "/users",
        component: lazy(() => import("./views/User/UserList")),
        title: "TA|Inventory-Users",
      },

      {
        exact: true,
        path: "/users/create",
        component: lazy(() => import("./views/User/UserCreate")),
        title: "TA|User-create",
      },
      {
        exact: true,
        path: "/users/edit/:id",
        component: lazy(() => import("./views/User/UserEdit")),
        title: "TA|User-edit",
      },
      {
        exact: true,
        path: "/users/view/:_id",
        component: lazy(() => import("./views/User/UserShow")),
        title: "TA|User-view",
      },
      {
        exact: true,
        path: "/materials",
        component: lazy(() => import("./views/Material/MaterialList")),
        title: "TA|Materials",
      },
      {
        exact: true,
        path: "/requests",
        component: lazy(() => import("./views/Request/RequestList")),
        title: "TA|Requests",
      },
      {
        exact: true,
        path: "/status",
        component: lazy(() => import("./views/Status/StatusList")),
        title: "TA|Status",
      },
      {
        exact: true,
        path: "/brand",
        component: lazy(() => import("./views/brand/brandList")),
        title: "TA|Brands",
      },
      {
        exact: true,
        path: "/motif",
        component: lazy(() => import("./views/Motif/motifList")),
        title: "TA|Motifs",
      },
      {
        exact: true,
        path: "/rejectMotif",
        component: lazy(() => import("./views/rejectMotif/rejectModifList")),
        title: "TA|rejectMotifs",
      },
      {
        exact: true,
        path: "/type",
        component: lazy(() => import("./views/Type/typeList")),
        title: "TA|types",
      },
      {
        exact: true,
        path: "/department",
        component: lazy(() => import("./views/Department/DepartmentList")),
        title: "TA|deprartments",
      },
      {
        exact: true,
        path: "/locations",
        component: lazy(() => import("./views/Location/locationList")),
        title: "TA|locations",
      },
      {
        exact: true,
        path: "/modeles",
        component: lazy(() => import("./views/Modele/modeleList")),
        title: "TA|modeles",
      },
      {
        exact: true,
        path: "/userMaterial",
        component: lazy(() => import("./views/User Material/UserMaterialList")),
        title: "TA|UserMaterial",
      },
      {
        exact: true,
        path: "/materials/create",
        component: lazy(() => import("./views/Material/MaterialCreate")),
        title: "TA|Materials-create",
      },
      {
        exact: true,
        path: "/requests/create",
        component: lazy(() => import("./views/Request/RequestCreate")),
        title: "TA|Requests-create",
      },
      {
        exact: true,
        path: "/requests/reclaim/:id",
        component: lazy(() => import("./views/Request/RequestCreate")),
        title: "TA|Requests-create",
      },
      {
        exact: true,
        path: "/status/create",
        component: lazy(() => import("./views/Status/StatusCreate")),
        title: "TA|Status-create",
      },
      {
        exact: true,
        path: "/brand/create",
        component: lazy(() => import("./views/brand/brandCreate")),
        title: "TA|Brand-create",
      },
      {
        exact: true,
        path: "/motif/create",
        component: lazy(() => import("./views/Motif/motifCreate")),
        title: "TA|Motif-create",
      },
      {
        exact: true,
        path: "/rejectMotif/create",
        component: lazy(() => import("./views/rejectMotif/rejectMotifCreate")),
        title: "TA|rejectMotif-create",
      },
      {
        exact: true,
        path: "/type/create",
        component: lazy(() => import("./views/Type/typeCreate")),
        title: "TA|Type-create",
      },
      {
        exact: true,
        path: "/department/create",
        component: lazy(() => import("./views/Department/DepartmentCreate")),
        title: "TA|Department-create",
      },
      {
        exact: true,
        path: "/location/create",
        component: lazy(() => import("./views/Location/locationCreate")),
        title: "TA|Location-create",
      },
      {
        exact: true,
        path: "/modeles/create",
        component: lazy(() => import("./views/Modele/modeleCreate")),
        title: "TA|Modele-create",
      },
      {
        exact: true,
        path: "/userMaterial/create",
        component: lazy(() =>
          import("./views/User Material/userMaterialCreate")
        ),
        title: "TA|UserMaterial-create",
      },
      {
        exact: true,
        path: "/materials/view/:id",
        component: lazy(() => import("./views/Material/MaterialShow")),
        title: "TA|Material-show",
      },
      {
        exact: true,
        path: "/requests/view/:id",
        component: lazy(() => import("./views/Request/RequestShow")),
        title: "TA|Request-show",
      },
      {
        exact: true,
        path: "/status/view/:id",
        component: lazy(() => import("./views/Status/StatusShow")),
        title: "TA|Status-show",
      },
      {
        exact: true,
        path: "/brand/view/:id",
        component: lazy(() => import("./views/brand/BrandShow")),
        title: "TA|Brand-show",
      },
      {
        exact: true,
        path: "/motif/view/:id",
        component: lazy(() => import("./views/Motif/motifShow")),
        title: "TA|Motif-show",
      },
      {
        exact: true,
        path: "/rejectMotif/view/:id",
        component: lazy(() => import("./views/rejectMotif/rejectMotifShow")),
        title: "TA|rejectMotif-show",
      },
      {
        exact: true,
        path: "/type/view/:id",
        component: lazy(() => import("./views/Type/TypeShow")),
        title: "TA|Type-show",
      },

      {
        exact: true,
        path: "/department/view/:id",
        component: lazy(() => import("./views/Department/DepartmentShow")),
        title: "TA|department-show",
      },
      {
        exact: true,
        path: "/location/view/:id",
        component: lazy(() => import("./views/Location/locationShow")),
        title: "TA|location-show",
      },
      {
        exact: true,
        path: "/modeles/view/:id",
        component: lazy(() => import("./views/Modele/modeleShow")),
        title: "TA|modele-show",
      },
      {
        exact: true,
        path: "/userMaterial/view/:id",
        component: lazy(() => import("./views/User Material/UserMaterialShow")),
        title: "TA|UserMaterial-show",
      },
      {
        exact: true,
        path: "/materials/edit/:id",
        component: lazy(() => import("./views/Material/MaterialEdit")),
        title: "TA|Material-edit",
      },
      {
        exact: true,
        path: "/requests/edit/:id",
        component: lazy(() => import("./views/Request/RequestEdit")),
        title: "TA|Request-edit",
      },
      {
        exact: true,
        path: "/status/edit/:id",
        component: lazy(() => import("./views/Status/StatusEdit")),
        title: "TA|status-edit",
      },
      {
        exact: true,
        path: "/brand/edit/:id",
        component: lazy(() => import("./views/brand/brandEdit")),
        title: "TA|Brand-edit",
      },
      {
        exact: true,
        path: "/motif/edit/:id",
        component: lazy(() => import("./views/Motif/motifEdit")),
        title: "TA|Motif-edit",
      },
      {
        exact: true,
        path: "/rejectMotif/edit/:id",
        component: lazy(() => import("./views/rejectMotif/rejectMotifEdit")),
        title: "TA|rejectMotif-edit",
      },
      {
        exact: true,
        path: "/type/edit/:id",
        component: lazy(() => import("./views/Type/typeEdit")),
        title: "TA|Type-edit",
      },
      {
        exact: true,
        path: "/department/edit/:id",
        component: lazy(() => import("./views/Department/DepartmentEdit")),
        title: "TA|Department-edit",
      },
      {
        exact: true,
        path: "/location/edit/:id",
        component: lazy(() => import("./views/Location/locationEdit")),
        title: "TA|location-edit",
      },
      {
        exact: true,
        path: "/modeles/edit/:id",
        component: lazy(() => import("./views/Modele/modeleEdit")),
        title: "TA|modele-edit",
      },
      {
        exact: true,
        path: "/userMaterial/edit/:id",
        component: lazy(() => import("./views/User Material/UserMaterialEdit")),
        title: "TA|UserMaterial-edit",
      },
      {
        exact: true,
        path: "/settings/roles/edit/:id",
        component: lazy(() => import("./views/AdminRoles/AdminRoleEdit")),
        title: "TA|AdminRole-edit",
      },
      {
        exact: true,
        path: "/settings/roles/create",
        component: lazy(() => import("./views/AdminRoles/AdminRoleCreate")),
        title: "TA|AdminRole-create",
      },
      {
        exact: true,
        path: "/settings/roles",
        component: lazy(() => import("./views/AdminRoles/AdminRoleList")),
        title: "TA|AdminRole-List",
      },
      {
        exact: true,
        path: "/settings/roles/view/:id",
        component: lazy(() => import("./views/AdminRoles/AdminRoleShow")),
        title: "TA|AdminRole-List",
      },
      {
        exact: true,
        path: "/settings/permissions",
        component: lazy(() => import("./views/Permission/PermissionList")),
        title: "TA|Permissions-List",
      },
      {
        path: "*",
        component: lazy(() => import("./views/NotFound")),
        title: "TA|Not found",
      },
    ],
  },
];


export default routes;
