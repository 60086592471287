// @flow
import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Card, CardContent, CardHeader, Divider, Grid, Slide, TextField, CardMedia, InputLabel, MenuItem, Select, } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SnackBar from '../../../components/SnackBar';
import { AsyncPaginate } from 'react-select-async-paginate';
import theme from '../../../theme';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { getAllDepartments } from 'redux/slices/department/departmentSlice';
import { createUser, editUser, showUser, getUser, importFileUsers} from 'redux/slices/auth/userSlice';
import { getMaterials, getDepartments, getLocations, getRoles, getPermissions } from '../../../utils/methods';
import { getAllMateriels } from 'redux/slices/materials/materialsSlice';
import { enqueueSnackbar } from 'redux/slices/notifier/notifierSlice';
import LoadingApi from 'components/LoadingApi/LoadingApi';
import { getAllLocations } from 'redux/slices/location/locationSlice';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GetAppIcon from '@material-ui/icons/GetApp';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    visibility: 'hidden',
    height: '0px',
    padding: '0',
    margin: '0'
  },
  importButton: {
    marginRight: theme.spacing(2),

  },
  downloadButton: {
    marginLeft: theme.spacing(2),

  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
}));
type Props = {
  edit: boolean,
  className: string
}
const initialState = {
  username: null,
  email: null,
  password: null,
  department_id: "",
  materiel_id: [],
  role: null ,
  permissions: [],
  phoneNumber: null,
  location_id: "",
}
const Form = (props: Props): React$Element<any> => {
  const { id } = useParams();
  const { className, edit, ...rest } = props;
  const classes = useStyles();
  const [values, setValues] = useState(initialState);
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertseverity] = useState('');
  const [importedUsers, getImportedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [departmentInput, setDepartmentInput] = useState('');
  const [materialInput, setMaterialInput] = useState('');
  const [locationInput, setLocationInput] = useState('');
  const [roleInput, setRoleInput] = useState('');
  const [permissionInput, setPermissionInput] = useState('');

  const { singleUser } = useSelector((state) => state.auth);
  const { importedData } = useSelector((state) => state.auth);

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllMateriels({ page: 1, options: null }));
    dispatch(getAllDepartments({ page: 1, options: null }));
    dispatch(getAllLocations({ page: 1, options: null }))
  }, []);

  useEffect(() => {
    const handleKeyPress = (e) => {
        const {keyCode} = e;
        if( keyCode === 13) {
          e.preventDefault();
          handleSubmit(e);
        }
    }
      window.addEventListener('keydown',handleKeyPress);
    return () => {
      window.removeEventListener('keydown',handleKeyPress);
    }
  },); 

  useEffect(() => {
    if (edit) {
      dispatch(showUser(id));
    }
  }, []);

  useEffect(() => {
    if (singleUser.status === 'succeeded' && edit) {
      setLoading(false);
      setValues({
        username: singleUser.data.username,
        email: singleUser.data.email,
        password: singleUser.data.password,
        department_id: singleUser.data.department_id,
        materiel_id: singleUser.data.materiel_id,
        role: singleUser.data.role,
        permissions: singleUser.data.permissions,
        location_id: singleUser.data.location_id,
        phoneNumber: singleUser.data.phoneNumber,
      });
    }
  }, [singleUser.status, edit]);


  const onFileUploadChange = (e) => {
    const file = e.target.files[0];
    onFileUpload(file);
  };

  const onFileUpload = (file) => {
    dispatch(importFileUsers({requestValues : {user : file}}));
  };

  useEffect(() => {
     if (values.role?.label === 'USER'){
      setIsUser(true);
     }else if (values.role?.label !== 'USER'){
      setIsUser(false);
     }
  }, [values.role?.label]);
  

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleDepartmentChange = (options) => {
    setValues({
      ...values,
      department_id: options,
    });
  };
  const handleMaterialChange = (options) => {
    setValues({
      ...values,
      materiel_id: options,
    });

  };
  const handleRoleChange = (options) => {
    setValues({
      ...values,
      role: options,
    });

  };

  const handlePermissionChange = (options) => {
    setValues({
      ...values,
      permissions: options,
    });
  };
  const handleLocationChange = (options) => {
    setValues({
      ...values,
      location_id: options,
    });
  };

  const registerValidation = (values) => {
    if (!values.username) {
      return { success: false, 'message': 'Username  is missing' };
    }
    if (!values.department_id) {
      return { success: false, 'message': 'Department  is missing' };
    }
    if (!values.location_id) {
      return { success: false, 'message': 'Location  is missing' };
    }
    if (!values.password && values.role?.label !== "USER" && !edit) {
      return { success: false, 'message': 'Password  is missing' };
    }
    if (!values.email && values.role?.label !== "USER") {
      return { success: false, 'message': 'Email  is missing' };
    }
    if (!values.phoneNumber) {
      return { success: false, 'message': 'Phone  is missing' };
    }
    if (values.phoneNumber.length !=8 || isNaN(values.phoneNumber)) {
      return { success: false, 'message': 'Phone  is invalid' };
    }
    if (!values.role) {
      return { success: false, 'message': 'Role  is missing' };
    }
    if (values.password && values.password.length < 8) {
      return { success: false, 'message': 'Password is invalid' };
    }
  };

  const handleSubmit = () => {
    const validation = registerValidation(values);
    if (validation) {
      dispatch(
        enqueueSnackbar({
          message: validation.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
    else {
      const requestValues = mapStateToRequest(values,
        ['materiel_id', 'permissions']);
      let newArr = [];
      let newArr2 = [];
      requestValues.materiel_id && requestValues.materiel_id.map(option => {
        newArr.push(option?._id)
      })
      requestValues.materiel_id = newArr;
      requestValues.permissions && requestValues.permissions.map(option => {
        newArr2.push(option?._id);
      })
      requestValues.permissions = newArr2;
      requestValues.department_id = values.department_id?._id;
      requestValues.location_id = values?.location_id?._id;
      requestValues.role = values?.role._id;

      if(requestValues.phoneNumber === ""){
        requestValues.phoneNumber = null;
       }
       if(requestValues.email === ""){
        requestValues.email = null;
       }
      if (edit) {
        requestValues.id = id;
        dispatch(editUser(requestValues))

      } else {
        dispatch(createUser(requestValues))
      }
    }
  };


  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  }


  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <CardMedia>
          <Button
            component="label"
            color="primary"
            variant="outlined"
            fullWidth
            startIcon={<CloudUploadIcon />}
            className={classes.importButton}
          >
            Import
            <input
              type="file"
              style={{ display: 'none' }}
              onChange={onFileUploadChange}
            />
            {importedData.status === 'loading' && <CircularProgress size={20} style={{ margin: 'auto auto' }} />}
          </Button>
        </CardMedia>
        <CardMedia>
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            startIcon={<GetAppIcon />}
            component={"a"}
            href={"/samples/users.xlsx"}
            className={classes.downloadButton}>
            Download Sample
          </Button>
        </CardMedia>
      </div>
      <form
        autoComplete="off"
        noValidate
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Card>
              <CardHeader
                subheader="User form"
              />
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      required
                      fullWidth
                      label="UserName"
                      name="username"
                      onChange={handleChange}

                      value={values.username || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      required={values.role?.label !== "USER" ? true : false}
                      fullWidth
                      label='Email Address'
                      name='email'
                      onChange={handleChange}
                      value={values.email || ''}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <AsyncPaginate
                      loadOptions={getDepartments}
                      value={values.department_id}
                      onChange={handleDepartmentChange}
                      getOptionLabel={(option) =>
                        option?.name
                      }
                      getOptionValue={(option) => option._id}
                      placeholder='Department *'
                      required
                      inputValue={departmentInput}
                      onInputChange={setDepartmentInput}
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      required
                      fullWidth
                      label='Phone Number'
                      name='phoneNumber'
                      onChange={handleChange}
                      value={values.phoneNumber || ''}
                      type='number'
                      variant='outlined'
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <AsyncPaginate
                      required
                      loadOptions={getLocations}
                      value={values?.location_id}
                      onChange={handleLocationChange}
                      getOptionLabel={(option) =>
                        option?.name
                      }
                      getOptionValue={(option) => option._id}
                      inputValue={locationInput}
                      onInputChange={setLocationInput}
                      placeholder='Locations *'
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                    />
                  </Grid>
                  {!edit ? (
                    <Grid item md={6} xs={12}>
                      <AsyncPaginate
                        loadOptions={getMaterials}
                        value={values.materiel_id}
                        onChange={handleMaterialChange}
                        getOptionLabel={(option) =>
                          option?.name + ' - ' + `${option?.serial_number ? option?.serial_number : '*****'}`
                        }
                        getOptionValue={(option) => option._id}
                        inputValue={materialInput}
                        onInputChange={setMaterialInput}
                        isMulti
                        placeholder='Materials'
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            minHeight: '53px',
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: '#000',
                          }),
                        }}
                        menuPortalTarget={document.querySelector('body')}
                      />
                    </Grid>) : (
                    <Grid
                      item
                      md={6}
                      xs={12}
                    ></Grid>
                  )}
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                  <AsyncPaginate
                        loadOptions={getRoles}
                        value={values.role}
                        onChange={handleRoleChange}
                        getOptionLabel={(option) =>option?.label}
                        getOptionValue={(option) => option._id}
                        inputValue={roleInput}
                        onInputChange={setRoleInput}
                        placeholder='Role*'
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            minHeight: '53px',
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: '#000',
                          }),
                        }}
                        menuPortalTarget={document.querySelector('body')}
                    />
                  </Grid>
                  {!isUser && (
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label='Password'
                        type='password'
                        name='password'
                        required={values.role?.label !== "USER" && !edit ? true : false}
                        onChange={handleChange}
                        value={values.password || ''}
                        variant='outlined'
                      />
                    </Grid>
                  )}
                 {!isUser && (

                   <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <AsyncPaginate
                      loadOptions={getPermissions}
                      value={values.permissions}
                      onChange={handlePermissionChange}
                      getOptionLabel={(option) =>
                        option?.label
                      }
                      getOptionValue={(option) => option._id}
                      inputValue={permissionInput}
                      onInputChange={setPermissionInput}
                      placeholder='Permissions'
                      isMulti
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                    />
                  </Grid> )}
                </Grid>
              </CardContent>
            </Card>
            <Box mt={3}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Save details
              </Button>
            </Box>
          </Grid>

        </Grid>
      </form>
      <SnackBar open={open} message={message} severity={severity}
        handleClose={handleClose} />
      <LoadingApi open={singleUser.status === "loading"} onClick={handleCloseLoading} />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
  loading: PropTypes.bool,

};

export default Form;