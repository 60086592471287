import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import SnackBar from '../SnackBar';
import { sendUserInvitation } from 'redux/slices/auth/userSlice';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0,
    },
}));
const Invite = (props) => {
    const dispatch = useDispatch();
    const whichUrl = useSelector((state) => state.show.showSubstance);

    const {
        className,
        handleInvitation,
        open,
        itemId,
        service,
        pageLink,
        restore,
        initializeData,
        rowsPerPage,
        replace,
        closeActions,
        ...rest
    } = props;
    const classes = useStyles();
    const [openAlert, setOpenAlert] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [eventId, setEventId] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [severity, setAlertSeverity] = React.useState('success');

    const handleCheckChange = () => {
        setChecked(!checked);
    };

    const handleClose = () => {
        setOpenAlert(false);
        setChecked(false);
        setEventId('');
    };

    const inviteHandler = () => {
        const deletedBasedUrl = whichUrl.toString().slice(-20);
        if (deletedBasedUrl.includes('users')) {
            dispatch(sendUserInvitation(itemId));
        }
        handleInvitation();
        setTimeout(() => {
            initializeData();
            closeActions();
        }, 500);
    };

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <Dialog
                open={open}
                onClose={handleInvitation}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>Delete Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Are you sure to send user'Invitation?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleInvitation} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={inviteHandler} color='primary' autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackBar
                open={openAlert}
                handleClose={handleClose}
                message={message}
                severity={severity}
            />
        </div>
    );
};


Invite.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    handleInvitation: PropTypes.func,
    itemId: PropTypes.number,
};

export default Invite;
