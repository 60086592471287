import { Slide } from "@material-ui/core";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "../notifier/notifierSlice";
import { getUserMaterialId } from "utils/methods";

import axios from "./../../../utils/axios";
import { useSelector } from "react-redux";
import { getUser } from "../auth/userSlice";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// urls:
const url = "/requests";
// const historyUrl = "/user-materiels";

// fetch all materials from server
export const getAllRequests = createAsyncThunk(
  "requests/getAll",
  async ({ page, options, rowsPerPage }, thunkAPI) => {
    try {
      const fetchedRequests = await axios.get(
        url + `?page=${page}${options}&limit=${rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (fetchedRequests.status === 200) {
        const { data } = fetchedRequests;
        return data;
      }
      throw new Error(fetchedRequests.statusText);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// fetch one material

export const getOneRequest = createAsyncThunk(
  "requests/getOne",
  async (requestId, thunkAPI) => {
    try {
      const fetchedSingleMaterial = await axios.get(`${url}/${requestId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (fetchedSingleMaterial.status === 200) {
        return fetchedSingleMaterial.data.data;
      }
      throw new Error(fetchedSingleMaterial.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// create material
export const createRequest = createAsyncThunk(
  "request/create",
  async (requestToCreate, thunkAPI) => {
    try {
      const createdRequest = await axios.post(
        url,
        requestToCreate,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      // const data = createdMaterial.data
      if (createdRequest.data.status === "success") {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              createdRequest?.data?.message ||
              "Material is successfully created",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
      }
      if (createdRequest.data.status === "fail") {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              createdRequest?.data?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
      }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message:
            err.message || "Something went wrong, Could not create material",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : "Something went wrong");
    }
  }
);
// update material
export const editRequest = createAsyncThunk(
  "request/edit",
  async (updateRequestData, thunkAPI) => {
    try {
      const updatedRequest = await axios.patch(
        `${url}/${updateRequestData?.id}`,
        updateRequestData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (updatedRequest.status === 200) {
        thunkAPI.dispatch(enqueueSnackbar({
          message:
            updatedRequest?.data?.message ||
            "Request is successfully updated",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          },
        }))
        return updatedRequest.data.data;
      }

    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message:
            err.message || "Something went wrong, Could not update request",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : "Something went wrong");
    }
  }
);

// delete request
export const deleteRequest = createAsyncThunk(
  "request/delete",
  async (requestlId, thunkAPI) => {
    try {
      const deletedRequest = await axios.delete(`${url}/${requestlId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (deletedRequest.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              deletedRequest?.data?.message ||
              "Request is successfully removed",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return deletedRequest.data.data;
      }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message:
            err.message || "Something went wrong, Could not remove material",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log('err', err);
      return Promise.reject(err.message ? err.message : "Something went wrong");
    }
  }
);


const initialBrowserState = {
  result: null,
  status: "idle",
  requests: [],
  singleRequest: {
    data: [],
    status: "idle",
    error: null,
  },
  editRequest: {
    data: [],
    status: "idle",
    error: null,
  },
  meta: null,
  occasionalMessage: null,
  createdRequests: {
    data: [],
    status: 'idle',
    error: null,
  }
};

// slice
const requestsSlice = createSlice({
  name: "requestSlice",
  initialState: initialBrowserState,
  reducers: {
    resetEditRequest: (state, action) => {
      state.editRequest = {
        data: [],
        status: "idle",
        error: null,
      }
    },
  },
  extraReducers: (builder) => {
    //reducers
    builder
      // get materials request
      .addCase(getAllRequests.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllRequests.fulfilled, (state, action) => {
        state.status = "succeeded"
        if (action.payload?.data.length >= 0)
          state.requests = action.payload;
      })
      .addCase(getAllRequests.rejected, (state, action) => {
        state.status = "failed";
      })
      // get a material
      .addCase(getOneRequest.pending, (state, action) => {
        state.singleRequest.status = "loading";
      })
      .addCase(getOneRequest.fulfilled, (state, action) => {
        state.singleRequest.status = "succeeded";
        state.singleRequest.data = action.payload;
      })
      .addCase(getOneRequest.rejected, (state, action) => {
        state.singleRequest.status = "failed";
        state.singleRequest.error = action.payload;
      })
      // create  request
      .addCase(createRequest.pending, (state, action) => {
        state.createdRequests.status = "loading";
      })
      .addCase(createRequest.fulfilled, (state, action) => {
        state.createdRequests.status = "succeeded";
      })
      .addCase(createRequest.rejected, (state, action) => {
        state.createdRequests.status = "failed";
      })
      // edit  request
      .addCase(editRequest.pending, (state, action) => {
        state.editRequest.status = 'pending'
      })
      .addCase(editRequest.fulfilled, (state, action) => {
        state.editRequest.status = 'succeeded';
        state.editRequest.data = action.payload;
      })
      .addCase(editRequest.rejected, (state, action) => {
        state.editRequest.status = 'failed'
        state.occasionalMessage = action.payload;
      })
      // remove material request
      .addCase(deleteRequest.pending, (state, action) => { })
      .addCase(deleteRequest.fulfilled, (state, action) => { })
      .addCase(deleteRequest.rejected, (state, action) => {
        state.occasionalMessage = action.payload;
      })
  },
});

export const { resetEditRequest } = requestsSlice.actions;
export default requestsSlice.reducer;
