import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_delete_many } from "utils/Api";
import { enqueueSnackbar } from "../notifier/notifierSlice";
import { Slide } from "@material-ui/core";


export const actionDeleteMany = createAsyncThunk(
  'action/deleteMany',
  async (query, thunkAPI) => {
    const { deleteUrl, selectedItems } = query;
    console.log({ query });
    let data;
    try {
      const response = api_delete_many(deleteUrl, selectedItems, false);
      data = await response;
      if (response.status = "success") {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const initialState = {
  deleteMany: {
    data: [],
    status: "idle",
    error: "null",
  }
};

const deleteSlice = createSlice({
  name: 'deleteSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [actionDeleteMany.pending]: (state, action) => {
      state.deleteMany.status = "loading";
    },
    [actionDeleteMany.fulfilled]: (state, action) => {
      state.deleteMany.status = "succeeded";
      state.deleteMany.data = action.payload;
    },
    [actionDeleteMany.rejected]: (state, action) => {
      state.deleteMany.status = "failed";
      state.deleteMany.error = action.payload;
    },
  }

});

export default deleteSlice.reducer;
