import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import { setItemFromStorage } from '../../../../../../utils/Storage.js';
import { api_get } from 'utils/Api';
import { getUser } from 'redux/slices/auth/userSlice.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const Profile = (props) => {
  const { log } = console;
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { className, profil, ...rest } = props;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);

  // useEffect(() => {
  //   dispatch(getUser());
  // }, []);

  const inventoryUser = useSelector((state) => state.auth.user);
  useEffect(() => {
    if (data) {
      if (data.roles) {
        setUser({
          ...user,
          name: (data && data.name) || '',
          bio: data?.roles[0] || '',
        });
      }
    }
  }, [data]);
  // const setPermissions= async (permissions)=>{
  //   await permissions && permissions.map(permission=>{
  //     return permission.permissions.map(async(perm,index)=>{
  //       await setItemFromStorage(perm.front_path,"permission");
  //       await setItemFromStorage(perm.menu,"permission");
  //     })
  //   })
  // }
  const profileRef = useRef(null);


  return (
    <div ref={profileRef} {...rest} className={clsx(classes.root, className)}>
          <Avatar
            alt='Person'
            className={classes.avatar}
            component={RouterLink}
            src={user.avatar}
            to='/account'
          />
      {profil ? (
        <Typography className={classes.name} variant='h4'>
          {(inventoryUser && inventoryUser?.username) || ''}
        </Typography>
      ) : (
        <br />
      )}
      {profil ? (
        <Typography variant='body2'>ROLE_{inventoryUser?.role?.label}</Typography>
      ) : (
        <br />
      )}
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
