import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { FormEdit } from './components';
import SimpleToolbar from '../../components/Toolbar/SimpleToolbar';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const MaterialEdit = (props) => {
  const { Link, pageLink, title, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SimpleToolbar Link={Link} pageLink={'/materials'} title={'Materials'} />
      <div className={classes.content}>
        <FormEdit edit={true} />
      </div>
    </div>
  );
};

MaterialEdit.propTypes = {
  user: PropTypes.object,
};
export default MaterialEdit;
