import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAllInventoryUsers } from 'redux/slices/auth/userSlice';
import TableList from "components/List/TableList";
import Loading from "components/Loading/Loading";


const columnsList = [
  {
    accessor: '_id',
    name: '_id',
    label: 'Id',
    width: '20%',
    show: true,
    sortable: true,
    type: 'idType',
  },
  {
    accessor: 'username',
    name: 'username',
    label: 'UserName',
    width: '20%',
    show: true,
    sortable: true,
    // type: 'textChip',
    type: 'fieldValue',
  },
  {
    accessor: 'email',
    label: 'Email',
    width: '30%',
    show: true,
    //  type: 'swichChipOne',
    type: 'swichChipOne',
  },
  {
    accessor: 'role.label',
    name: 'role.label',
    label: 'Role',
    width: '20%',
    show: true,
    type: 'differentChip',
    // type: 'fieldValue',
  },
  {
    accessor: 'createdAt',
    name: 'createdAt',
    label: 'Created At',
    width: '20%',
    show: true,
    sortable: true,
    type: 'date',
  },
];
const fieldFilterable = [
  {
    name: 'username',
    label: 'Username',
    type: 'text',
  },
  {
    name: 'email',
    label: 'Email',
    type: 'text',
  },
  {
    name: 'role',
    label: 'Role',
    type: 'roleAutocomplete',
  },
  // {
  //   name: 'createdAt',
  //   label: 'Created At',
  //   type: 'date',
  // },
];

const UserList = (props) => {
  const data = useSelector((state) => state.auth.inventoryUsers);
  const { status: sendUserInvitationStatus } = useSelector(state => state.auth.sendUserInvitation)

  // const [superAdminTrue, setIsSuperAdminTrue] = useState(false);

  // const superAdminIsTrue = (data) => {
  //   let rolesArr = data?.map((item)=> item?.role?.label)
  //   if(rolesArr.includes("SUPER_ADMIN")){
  //     return true;
  //   }
  // }
  // useEffect(() => {
  //   if(data.status === 'succeeded')
  //   if (superAdminIsTrue(data?.data?.data) === true) {
  //     setIsSuperAdminTrue(true);
  //   }
  // }, [data.status]);

  return (
    <>
      {sendUserInvitationStatus === 'loading' && <Loading />}
      <TableList
        dispatchFunction={getAllInventoryUsers}
        data={data.data}
        columnsList={columnsList}
        fieldFilterable={fieldFilterable}
        title={'Inventory users'}
        pageLink={'/users'}
        searchMessage={"Search (Username, Email, Phone Number)"}
        deleteUrl={'users'}
      // noDelete={superAdminTrue ? true : false}
      />
    </>

  );
};

export default UserList;
